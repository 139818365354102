import React from 'react';

import { Cell } from 'react-table';
import { Call } from 'app/models/call';
import { ModalService } from 'app/services/modal-service/modal.service';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ConsumersActions, ConsumersSelectors } from 'store/consumers';
import { CALLS_HISTORY_COLUMNS, ConsumerCallsHistoryCell } from '../../../../components/Tabs/constants/calls-history.columns';
import { CallDialog } from '../../../Calls/components/CallDialog';
import { EmptyTable, FileIcon, Text } from '../../../../components/Tabs/constants/elements';
import { CallHistoryTable } from '../../../../components/Tabs/CallHistoryTab/styled';
import { ConsumerId } from '../../../../models/consumer';

interface CallHistoryTabProps {
  consumerId: ConsumerId | undefined;
}

export const CallHistoryTab = React.memo<CallHistoryTabProps>(props => {
  const dispatch = useDispatch();
  const calls = useSelector(ConsumersSelectors.selectConsumerCalls, shallowEqual);
  const isLoading = useSelector(ConsumersSelectors.selectAreConsumerCallsLoading, shallowEqual);
  const totalCount = useSelector(ConsumersSelectors.selectConsumerCallsTotalCount, shallowEqual);

  const [page, setPage] = React.useState(1);

  const cellHandleClick = (cell: Cell<Call>) => {
    const call = cell.row.original;

    if (cell.column.id !== ConsumerCallsHistoryCell.Record) {
      ModalService.openModal(CallDialog, { call });
    }
  };

  const handleNextPageLoad = React.useCallback(() => {
    setPage(prevState => prevState + 1);
  }, []);

  React.useEffect(() => {
    if (!props.consumerId) return;
    dispatch(
      ConsumersActions.LoadConsumerCalls.init({
        id: props.consumerId,
        page,
      })
    );
  }, [props.consumerId, page, dispatch]);

  if (!calls.length) {
    return (
      <EmptyTable>
        <FileIcon icon="/assets/icons.svg#phone down" />
        <Text>В истории нет звонков</Text>
      </EmptyTable>
    );
  }

  return (
    <CallHistoryTable
      isInfinity
      isLoading={isLoading && page === 1}
      nextPage={handleNextPageLoad}
      columns={CALLS_HISTORY_COLUMNS}
      data={calls}
      onCellClick={cellHandleClick}
      dataLength={totalCount}
    />
  );
});
