import { getCreateActionFromStore, getCreateActionTrio, getCreatePayloadActionFromStore } from '../helpers/action-create';
import { Setting } from '../../app/models/configuration';
import { TableConfiguration } from './models/get-appeals-configuration-success.payload';

export const CONFIGURATION_STORE_NAME = 'configuration';

export const createAction = getCreateActionFromStore(CONFIGURATION_STORE_NAME);
export const createPayloadAction = getCreatePayloadActionFromStore(CONFIGURATION_STORE_NAME);
export const createActionTrio = getCreateActionTrio(CONFIGURATION_STORE_NAME);

export const GetTablesConfiguration = createActionTrio<void, TableConfiguration>('get tables configuration');

export const GetSettings = createActionTrio<void, Setting[]>('get settings');
