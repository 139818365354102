import { AddressesData } from 'app/pages/Mailings/components/AddressesList';
import { HttpService } from '../http.service';
import { Mailing, MailingId, MailingsQueryParams } from '../../models/mailing';
import { CreateMailingRequest } from './dtos/create-mailing-request';
import { UpdateMailingRequest } from './dtos/update-mailing-request';
import { MailingTemplates } from './dtos/get-mailing-templates-request';

class MailingsServiceImpl {
  private static apiEndpoint = '/api/mailing';

  getMailings(query?: MailingsQueryParams): Promise<Mailing[]> {
    return HttpService.get<any[]>(MailingsServiceImpl.apiEndpoint, { params: query }).then(response => response.data);
  }

  getMailing(id: MailingId): Promise<Mailing> {
    return HttpService.get<Mailing>(`${MailingsServiceImpl.apiEndpoint}/${id}/`).then(response => response.data);
  }

  createMailing(createMailingDto: CreateMailingRequest): Promise<Mailing> {
    const { data, query } = createMailingDto;
    return HttpService.post<Mailing>(MailingsServiceImpl.apiEndpoint, data, { params: query }).then(response => response.data);
  }

  updateMailing(updateMailingDto: UpdateMailingRequest): Promise<Mailing> {
    const { id, data, query } = updateMailingDto;
    return HttpService.put<Mailing>(`${MailingsServiceImpl.apiEndpoint}/${id}/`, data, { params: query }).then(response => response.data);
  }

  deleteMailing(id: MailingId) {
    return HttpService.delete<void>(`${MailingsServiceImpl.apiEndpoint}/${id}/`);
  }

  getNumberRecipients(data: { addresses: AddressesData[] }): Promise<{ number: number }> {
    return HttpService.post<{ number: number }>(MailingsServiceImpl.apiEndpoint, data, { params: { number_info: true } }).then(
      response => response.data
    );
  }

  getMailingTemplates(): Promise<MailingTemplates> {
    return HttpService.get<MailingTemplates>(`${MailingsServiceImpl.apiEndpoint}/template/`).then(res => res.data);
  }
}

export const MailingsService = new MailingsServiceImpl();
