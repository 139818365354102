import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  GetSpeechNotificationsApiParams,
  SpeechNotificationId,
  SpeechNotificationService,
  UpdateSpeechNotificationApiParams,
  UpdateSpeechNotificationsApiParams,
} from 'app/services/speech-notification-service';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  GetSpeechNotification,
  GetSpeechNotifications,
  UpdateSpeechNotification,
  UpdateSpeechNotifications,
} from './speech-notification.actions';

function* getSpeechNotifications({ payload }: PayloadAction<GetSpeechNotificationsApiParams>) {
  try {
    const res = yield call(SpeechNotificationService.getSpeechNotifications, payload);

    yield res && put(GetSpeechNotifications.success(res));
  } catch (error) {
    yield put(GetSpeechNotifications.failure(error));
  }
}

function* getSpeechNotification({ payload }: PayloadAction<SpeechNotificationId>) {
  try {
    const res = yield call(SpeechNotificationService.getSpeechNotification, payload);

    yield res && put(GetSpeechNotification.success(res));
  } catch (error) {
    yield put(GetSpeechNotification.failure(error));
  }
}

function* updateSpeechNotification({ payload }: PayloadAction<UpdateSpeechNotificationApiParams>) {
  try {
    const res = yield call(SpeechNotificationService.updateSpeechNotification, payload);

    yield res && put(UpdateSpeechNotification.success(res));
  } catch (error) {
    yield put(UpdateSpeechNotification.failure(error));
  }
}

function* updateSpeechNotifications({ payload }: PayloadAction<UpdateSpeechNotificationsApiParams>) {
  try {
    const res = yield call(SpeechNotificationService.updateSpeechNotifications, payload);

    yield res && put(UpdateSpeechNotifications.success(res));
    yield res && put(GetSpeechNotifications.init({ page: 1, active: undefined }));
  } catch (error) {
    yield put(UpdateSpeechNotifications.failure(error));
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(GetSpeechNotifications.init, getSpeechNotifications),
    takeLatest(GetSpeechNotification.init, getSpeechNotification),
    takeLatest(UpdateSpeechNotification.init, updateSpeechNotification),
    takeLatest(UpdateSpeechNotifications.init, updateSpeechNotifications),
  ]);
}
