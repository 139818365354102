import React from 'react';
import { AccessDeniedDialog, CloseButton, ErrorText } from './styled';
import { DialogProps } from '../../shared';
import { useLockBodyScroll } from '../../../helpers';
import { Icons } from '../../../constants/icons';

type ServerErrorModalProps = { errorText: string } & Pick<DialogProps, 'onClose'>;
export const ServerErrorModal = (props: ServerErrorModalProps) => {
  useLockBodyScroll();

  const body = React.useMemo(() => {
    return (
      <>
        <Icons.ServerError />
        <ErrorText>{props.errorText}</ErrorText>
        <CloseButton onClick={props.onClose}>Понятно</CloseButton>
      </>
    );
  }, [props.errorText, props.onClose]);

  return <AccessDeniedDialog onClose={props.onClose} body={body} />;
};
