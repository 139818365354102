import styled from 'styled-components';
import { Colors } from 'app/themes/colors';
import { Flexbox } from 'app/typography/flex';

export const PageContainer = styled(Flexbox)`
  margin: 20px 30px 0 30px;
  min-height: calc(100vh - 72px);
  gap: 20px;
`;

export const PageWrapper = styled.div<{ small?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${Colors.MainBackground};
  border-radius: 10px;

  .infinite-scroll-component__outerdiv {
    flex-grow: 1;
  }
`;
