import { isSameDay, startOfDay } from 'date-fns';
import { date, object, string } from 'yup';

export const validationScheme = object().shape({
  date: date().min(startOfDay(new Date()), 'Нельзя создать уведомление в прошлом').required('Заполните дату'),
  time: string()
    .required('Заполните время')
    .when('date', ([date], scheme) => {
      const minDate = new Date();
      const isMinDay = isSameDay(minDate, date);
      if (isMinDay) {
        return scheme.test('is-min-time', 'Нельзя создать уведомление в прошлом', value => {
          const minHours = minDate.getHours();
          const minMinutes = minDate.getMinutes();
          const [hours, minutes] = value.split(':');
          return minHours === +hours ? minMinutes <= +minutes : minHours < +hours;
        });
      }
      return scheme;
    }),
});
