import { CreateMasterRequest } from 'app/services/master-service/dtos/create-master.request';
import { UpdateMasterRequest } from 'app/services/master-service/dtos/update-master.request';
import { Master } from 'app/models/master';
import { Option } from 'app/models/common/option';
import { CommonFiltersStructure } from 'app/models/configuration/table-structure';
import { MastersQueryParams } from 'app/models/master/masters-query-params';
import { ChangeChosenFilterPayload } from '../models/change-chosen-filter-payload';
import { getCreateActionTrio, getCreatePayloadActionFromStore } from '../helpers/action-create';

export const MASTERS_STORE_NAME = 'masters';

const createPayloadAction = getCreatePayloadActionFromStore(MASTERS_STORE_NAME);
const createActionTrio = getCreateActionTrio(MASTERS_STORE_NAME);

export const LoadMasters = createActionTrio<MastersQueryParams, { page: number; mastersTotalCount: number; masters: Master[] }>(
  'load masters'
);

export const GetMastersPositions = createActionTrio<void, Option<number>[]>('get masters positions');

export const CreateMaster = createActionTrio<CreateMasterRequest, Master>('create master');

export const UpdateMaster = createActionTrio<UpdateMasterRequest, Master>('update master');

export const DeleteMaster = createActionTrio<{ id: string }, { id: string }>('delete master');

export const setChosenFilter = createPayloadAction<{ filters: CommonFiltersStructure }>('set chosen filter');
export const changeChosenFilter = createPayloadAction<ChangeChosenFilterPayload>('change chosen filter');
export const resetSomeFilters = createPayloadAction<string[]>('reset some filters');
