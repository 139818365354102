export enum MailingStatus {
  Planned = 'planned',
  Sended = 'sended',
  Draft = 'draft',
  Error = 'error',
}

export const MailingStatusToLabelMap = {
  [MailingStatus.Planned]: 'Запланирована',
  [MailingStatus.Sended]: 'Отправлена',
  [MailingStatus.Draft]: 'Черновик',
  [MailingStatus.Error]: 'Ошибка',
};
