import { Consumer, ConsumerId, ConsumersQueryParams, GetConsumersCallApiParams } from 'app/models/consumer';
import { CancelToken } from 'axios';
import { HttpService } from '../http.service';
import { CreateConsumerRequest, UpdateConsumerRequest } from './dtos';
import { TableDto } from '../models/table.dto';
import { Call } from '../../models/call';

class ConsumersServiceImpl {
  private static apiEndpoint = '/api/consumers';

  getConsumers(params: ConsumersQueryParams): Promise<TableDto<Consumer>> {
    const { per_page, page, ...queryFilters } = params;

    const queryParams = {
      per_page,
      page,
      ...queryFilters,
    };

    return HttpService.get<TableDto<Consumer>>(`${ConsumersServiceImpl.apiEndpoint}/`, {
      params: queryParams,
    }).then(response => response.data);
  }

  getConsumer(id: ConsumerId): Promise<Consumer> {
    return HttpService.get<Consumer>(`${ConsumersServiceImpl.apiEndpoint}/${id}/`).then(response => response.data);
  }

  getConsumerCalls(params: GetConsumersCallApiParams): Promise<TableDto<Call>> {
    return HttpService.get<TableDto<Call>>(`${ConsumersServiceImpl.apiEndpoint}/${params.id}/calls/`, {
      params,
    }).then(response => response.data);
  }

  createConsumer(createConsumerDto: CreateConsumerRequest): Promise<Consumer> {
    return HttpService.post<Consumer>(ConsumersServiceImpl.apiEndpoint, createConsumerDto).then(response => response.data);
  }

  updateConsumer({ id, ...body }: UpdateConsumerRequest): Promise<Consumer> {
    return HttpService.put<Consumer>(`${ConsumersServiceImpl.apiEndpoint}/${id}/`, body).then(response => response.data);
  }

  deleteConsumer(id: ConsumerId) {
    return HttpService.delete<void>(`/api/consumers/${id}/`);
  }

  getConsumerByPhone(phone: string, cancelToken: CancelToken): Promise<Consumer | null> {
    return HttpService.get<TableDto<Consumer> | null>(ConsumersServiceImpl.apiEndpoint, {
      params: { phone },
      cancelToken,
    }).then(response => (response?.data?.results ? response?.data?.results?.[0] : null));
  }
}

export const ConsumersService = new ConsumersServiceImpl();
