import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, cancelled, delay, fork, put, race, select, takeLatest } from 'redux-saga/effects';
import history from 'app/history';
import { AppealsQueryParams, GetAppealCallsApiParams } from 'app/models/appeal/appeals-query-params';
import { AppealsService } from 'app/services/appeals-service/appeals.service';
import { UpdateAppealRequest } from 'app/services/appeals-service/dtos/update-appeal-request';
import { PagesRoutes } from 'app/constants/route-path';
import { AddFeedbackDto } from 'app/services/appeals-service/dtos/add-feedback-dto';
import { ChangeAppealSupplierDto } from 'app/services/appeals-service/dtos/change-appeal-supplier-request';
import { Appeal, AppealId } from 'app/models/appeal/appeal';
import { ModalService } from 'app/services/modal-service/modal.service';
import { AddFeedbackDialog } from 'app/pages/Appeal/dialogs/AddFeedbackDialog';
import { AppealSelectMasterDialog } from 'app/pages/Appeal/dialogs/AppealSelectMasterDialog';
import { AppealSelectSuplierDialog } from 'app/pages/Appeal/dialogs/AppealSelectSuplierDialog';
import { TableDto } from 'app/services/models/table.dto';
import { AccommodationService } from 'app/services/accommodation-service';
import { Address } from 'app/models/accommodation';
import { DEFAULT_ROWS_COUNT, MAX_ROWS_COUNT } from 'app/constants/scroll';
import { Option } from 'app/models/common/option';
import { WorkPlanDialog } from 'app/pages/Appeal/dialogs/WorkPlanDialog';
import { Call } from 'app/models/call';
import { APPEAL_STATUS } from 'app/constants/appeals';
import { profileSelectors } from 'store/profile';
import Axios from 'axios';
import { GetAppealWorkPricesRequest } from 'app/services/appeals-service/dtos/get-appeal-work-prices-request';
import { generatePath } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { UploadAppealAttachmentsPayload } from './models/upload-appeal-attachments.payload';
import * as AppealActions from './appeals.actions';
import { Profile } from '../../app/models/profile/profile';
import { CreateAppealPayload } from './models/create-appeal.payload';
import { AppealsActions } from '.';
import { AddIncidentToAppealDialog } from '../../app/pages/Appeal/dialogs/AddIncidentToAppealDialog/AddIncidentToAppealDialog';
import { AddNotification } from '../notifications/notifications.actions';
import { NotificationType } from '../../app/models/notifications/notification';
import { selectSelectedIncident } from '../incidents/incidents.selectors';
import { GetIncidentById } from '../incidents/incidents.actions';
import { CallsService } from '../../app/services/calls-service';
import { selectNewCall } from '../calls/calls.selectors';
import { webPhoneSelectors } from '../webphone';

function* getAppealsWorker({ payload }: PayloadAction<AppealsQueryParams>) {
  const cancelSource = Axios.CancelToken.source();
  try {
    const response: TableDto<Appeal> = yield call(
      AppealsService.getAppeals,
      {
        per_page: payload.per_page || DEFAULT_ROWS_COUNT,
        ...payload,
      },
      cancelSource.token
    );

    yield put(AppealActions.GetAppeals.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppeals.failure(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* getAppealsCreatedGteWorker({ payload }: PayloadAction<AppealsQueryParams>) {
  const cancelSource = Axios.CancelToken.source();
  try {
    const response: TableDto<Appeal> = yield call(
      AppealsService.getAppeals,
      {
        per_page: payload.per_page || DEFAULT_ROWS_COUNT,
        ...payload,
      },
      cancelSource.token
    );

    yield put(AppealActions.GetAppealsCreatedGte.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealsCreatedGte.failure(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* getAppealWorker({ payload }: PayloadAction<{ id: number }>) {
  try {
    const response = yield call(AppealsService.getAppeal, payload.id);

    yield put(AppealActions.GetAppealById.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealById.failure(e));
  }
}

function* createAppealWorker({ payload }: PayloadAction<CreateAppealPayload>) {
  const timeoutMs = 30000; // 30 секунд

  try {
    const source = Axios.CancelToken.source();

    const { result, timeout } = yield race({
      // eslint-disable-next-line func-names
      result: call(function* () {
        const profile: Profile = yield select(profileSelectors.selectProfile);

        const { address, addressId, dadataPayload, ...appeal } = payload;
        let accommodationId = addressId;

        if (!accommodationId) {
          const { id }: Address = yield call(AccommodationService.createAccommodation, {
            address,
            consumerId: appeal.consumerId,
            numberOfOccupants: 0,
            ...dadataPayload,
          });

          accommodationId = id;
        }

        const response = yield call(
          AppealsService.createAppeal,
          {
            ...appeal,
            addressId: accommodationId,
            dispatcherId: profile.id,
          },
          source.token
        );

        if (!response) {
          throw new Error('No response from AppealsService.createAppeal');
        }

        const newCall: Call | null = yield select(selectNewCall);
        const comment: string = yield select(webPhoneSelectors.selectDispatcherComment);

        yield response?.id &&
          newCall?.id &&
          call(CallsService.updateCall, newCall.id, {
            appealId: response.id,
            comment,
          });

        yield response?.id && put(AppealActions.CreateAppeal.success(response));

        response?.id &&
          history.push(
            generatePath(PagesRoutes.PAGES.APPEAL, {
              appealId: String(response?.id),
            })
          );
      }),
      timeout: delay(timeoutMs),
    });

    if (timeout) {
      source.cancel('Timeout: createAppealWorker did not finish within the timeout.');

      throw new Error(`Timeout: createAppealWorker did not finish within ${timeoutMs}ms`);
    }

    // Сохраняем результат выполнения саги
    return result;
  } catch (e: any) {
    // Отправляем ошибку в Sentry
    Sentry.captureException(e, {
      extra: {
        payload,
      },
    });

    yield put(AppealActions.CreateAppeal.failure(e));
  }
}

function* updateAppealWorker({ payload }: PayloadAction<UpdateAppealRequest>) {
  try {
    const { address, addressId, ...appeal } = payload;
    let accommodationId = addressId;

    if (!accommodationId) {
      const { id }: Address = yield call(AccommodationService.createAccommodation, {
        address,
        consumerId: appeal.consumerId,
      });

      accommodationId = id;
    }

    const response = yield call(AppealsService.updateAppeal, {
      ...appeal,
      addressId: accommodationId,
    });

    yield put(AppealActions.UpdateAppeal.success(response));
    yield response && put(AddNotification.init({ body: 'Успешно!', type: NotificationType.Info }));
  } catch (e: any) {
    yield put(AppealActions.UpdateAppeal.failure(e));
  }
}

function* updateIncidentAppealsWorker({ payload }: PayloadAction<UpdateAppealRequest>) {
  try {
    const response = yield call(AppealsService.updateAppeal, {
      ...payload,
    });

    const incident = yield select(selectSelectedIncident);

    const filteredAppeals = incident?.appeals?.filter((appeal: Appeal) => appeal.id !== payload.id);
    const count = incident?.appeals_count ? incident?.appeals_count - 1 : 0;

    yield response && put(AppealActions.UpdateAppeal.success(response));
    yield response && put(GetIncidentById.success({ ...incident, appeals: filteredAppeals, appeals_count: count }));
    yield response && put(AddNotification.init({ body: 'Успешно!', type: NotificationType.Info }));
  } catch (e: any) {
    yield put(AppealActions.UpdateAppeal.failure(e));
  }
}

function* deleteAppealWorker({ payload }: PayloadAction<{ id: number }>) {
  try {
    const response = yield call(AppealsService.deleteAppeal, payload.id);

    yield put(AppealActions.DeleteAppeal.success(response));
  } catch (e: any) {
    yield put(AppealActions.DeleteAppeal.failure(e));
  }
}

function* closeAppealWorker({ payload }: PayloadAction<AddFeedbackDto>) {
  try {
    const response: Appeal = yield call(AppealsService.closeAppeal, payload);

    yield put(AppealActions.CloseAppeal.success(response));
  } catch (e: any) {
    yield put(AppealActions.CloseAppeal.failure(e));
  }
}

function* navigateToUpdateAppealPageWorker({ payload }: PayloadAction<{ id: number }>) {
  try {
    yield put(AppealActions.GetAppealById.init(payload));
    yield put(AppealActions.NavigateToUpdateAppealPage.success());
    history.push(
      generatePath(PagesRoutes.PAGES.APPEAL_EDIT, {
        appealId: String(payload.id),
      })
    );
  } catch (e: any) {
    yield put(AppealActions.NavigateToUpdateAppealPage.failure(e));
  }
}

function* openAddFeedbackDialog({ payload }: PayloadAction<{ appeal: Appeal }>) {
  ModalService.openModal(AddFeedbackDialog, { appeal: payload.appeal });
}

function* assignMasterWorker({ payload }: PayloadAction<UpdateAppealRequest>) {
  try {
    const { address, addressId, ...appeal } = payload;
    let accommodationId = addressId;

    if (!accommodationId) {
      const { id }: Address = yield call(AccommodationService.createAccommodation, {
        address,
        consumerId: appeal.consumerId,
      });

      accommodationId = id;
    }

    const response = yield call(AppealsService.updateAppeal, {
      ...appeal,
      addressId: accommodationId,
    });

    yield put(AppealActions.AssignMaster.success(response));
  } catch (e: any) {
    yield put(AppealActions.AssignMaster.failure(e));
  }
}

function* closeAppealAfterFeedbackAddWorker({ payload }: PayloadAction<Appeal>) {
  yield put(AppealActions.ChangeAppealStatus.init({ id: payload.id, status: APPEAL_STATUS.closed }));
}

function* changeAppealStatusWorker({ payload }: PayloadAction<{ id: number; status: string }>) {
  try {
    const response: Appeal = yield call(AppealsService.changeAppealStatus, `${payload.id}`, payload.status);

    yield put(AppealActions.ChangeAppealStatus.success(response));
  } catch (e: any) {
    yield put(AppealActions.ChangeAppealStatus.failure(e));
  }
}

function* uploadAppealAttachment(appealId: number, file: File) {
  try {
    const attachment = yield call(AppealsService.uploadAttachment, appealId, file);

    yield put(AppealActions.UploadAppealAttachments.success(attachment));
  } catch (error: any) {
    if (error?.message) {
      yield put(AppealActions.UploadAppealAttachments.failure(error));
    } else {
      yield put(AppealActions.UploadAppealAttachments.failure({ message: 'Не удалось загрузить файл' }));
    }
  }
}

function* uploadAllAppealAttachments({ payload }: PayloadAction<UploadAppealAttachmentsPayload>) {
  yield all(payload.files.map(file => fork(uploadAppealAttachment, payload.appealId, file)));
}

function* deleteAppealAttachment({ payload }: PayloadAction<string>) {
  try {
    yield call(AppealsService.deleteAttachment, payload);

    yield put(AppealActions.DeleteAppealAttachment.success(payload));
  } catch (e: any) {
    yield put(AppealActions.DeleteAppealAttachment.failure(e));
  }
}

function* changeAppealTypeWorker({ payload }: PayloadAction<{ type: string; types: Option<string>[] }>) {
  yield put(AppealActions.setChosenFilter(payload.type));
}

function* openSelectMasterDialogWorker({ payload }: PayloadAction<{ appeal: Appeal }>) {
  ModalService.openModal(AppealSelectMasterDialog, { appeal: payload.appeal });
}

function* openWorkPlanDialogWorker({ payload }: PayloadAction<{ appeal: Appeal }>) {
  ModalService.openModal(WorkPlanDialog, { appeal: payload.appeal });
}

function* getAppealCalls({ payload }: PayloadAction<GetAppealCallsApiParams>) {
  try {
    const response = yield call(AppealsService.getAppealCalls, {
      per_page: payload.per_page || MAX_ROWS_COUNT,
      ...payload,
    });

    yield put(AppealActions.GetAppealCalls.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealCalls.failure(e));
  }
}

function* openSelectSupplierDialogWorker({ payload }: PayloadAction<{ appealId: number }>) {
  ModalService.openModal(AppealSelectSuplierDialog, { appealId: payload.appealId });
}
function* openAssignIncidentsDialogWorker() {
  ModalService.openModal(AddIncidentToAppealDialog, {});
}

function* changeAppealSupplierWorker({ payload }: PayloadAction<ChangeAppealSupplierDto>) {
  try {
    const id = yield call(AppealsService.changeAppealSupplier, payload);
    yield put(AppealActions.ChangeAppealSupplier.success(id));
    yield put(AppealActions.NavigateToAppealPage({ id }));
  } catch (e: any) {
    yield put(AppealActions.ChangeAppealSupplier.failure(e));
  }
}

function* navigateToAppealPageWorker({ payload }: PayloadAction<{ id: number }>) {
  yield put(AppealActions.GetAppealById.init(payload));

  history.push(
    generatePath(PagesRoutes.PAGES.APPEAL, {
      appealId: String(payload?.id),
    })
  );
}

function* getAppealTypesWorker() {
  try {
    const response = yield call(AppealsService.getAppealTypes);
    yield put(AppealActions.GetAppealTypes.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealTypes.failure(e));
  }
}

function* getAppealCategoriesWorker() {
  try {
    const response = yield call(AppealsService.getAppealCategories);
    yield put(AppealActions.GetAppealCategories.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealCategories.failure(e));
  }
}

function* getAppealKindsWorker() {
  try {
    const response = yield call(AppealsService.getAppealKinds);
    yield put(AppealActions.GetAppealKinds.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealKinds.failure(e));
  }
}

function* getAppealStatusesWorker() {
  try {
    const response = yield call(AppealsService.getAppealStatuses);
    yield put(AppealActions.GetAppealStatuses.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealStatuses.failure(e));
  }
}

function* getAppealAdditionalFieldsWorker() {
  try {
    const response = yield call(AppealsService.getAppealAdditionalFields);
    yield put(AppealActions.GetAppealAdditionalFields.success(response));
  } catch (e: any) {
    yield put(AppealActions.GetAppealAdditionalFields.failure(e));
  }
}

function* getAppealWorksWorker({ payload }: PayloadAction<GetAppealWorkPricesRequest | undefined>) {
  try {
    const response = yield call(AppealsService.getAppealWorks, payload);
    yield put(AppealsActions.GetAppealWorks.success(response));
  } catch (e: any) {
    yield put(AppealsActions.GetAppealWorks.failure(e));
  }
}

function* getAppealMessagesWorker({ payload }: PayloadAction<{ id: number }>) {
  try {
    const response = yield call(AppealsService.getAppealMessages, payload.id);
    yield put(AppealsActions.GetAppealMessages.success(response));
  } catch (e: any) {
    yield put(AppealsActions.GetAppealMessages.failure(e));
  }
}

function* setReadAppealMessagesWorker({ payload }: PayloadAction<{ id: number }>) {
  try {
    const response = yield call(AppealsService.setReadAppealMessages, payload.id);
    yield put(AppealsActions.SetReadAppealMessages.success(response));
  } catch (e: any) {
    yield put(AppealsActions.SetReadAppealMessages.failure(e));
  }
}

function* createAppealMessageWorker({ payload }: PayloadAction<{ id: number; text: string }>) {
  try {
    const response = yield call(AppealsService.createAppealMessage, payload);
    yield put(AppealsActions.CreateAppealMessage.success(response));
  } catch (e: any) {
    yield put(AppealsActions.CreateAppealMessage.failure(e));
  }
}

function* getAppealChangesHistory({ payload }: PayloadAction<{ id: AppealId }>) {
  try {
    const response = yield call(AppealsService.getAppealChangesHistory, payload.id);
    yield put(AppealsActions.GetAppealChangesHistory.success(response));
  } catch (e: any) {
    yield put(AppealsActions.GetAppealChangesHistory.failure(e));
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(AppealActions.GetAppeals.init, getAppealsWorker),
    takeLatest(AppealActions.GetAppealsCreatedGte.init, getAppealsCreatedGteWorker),
    takeLatest(AppealActions.GetAppealById.init, getAppealWorker),
    takeLatest(AppealActions.CreateAppeal.init, createAppealWorker),
    takeLatest(AppealActions.UpdateAppeal.init, updateAppealWorker),
    takeLatest(AppealActions.EditAppealIncidents.init, updateIncidentAppealsWorker),
    takeLatest(AppealActions.DeleteAppeal.init, deleteAppealWorker),
    takeLatest(AppealActions.NavigateToUpdateAppealPage.init, navigateToUpdateAppealPageWorker),
    takeLatest(AppealActions.CloseAppeal.init, closeAppealWorker),
    takeLatest(AppealActions.openAddFeedbackDialog, openAddFeedbackDialog),
    takeLatest(AppealActions.AssignMaster.init, assignMasterWorker),
    takeLatest(AppealActions.CloseAppeal.success, closeAppealAfterFeedbackAddWorker),
    takeLatest(AppealActions.ChangeAppealStatus.init, changeAppealStatusWorker),
    takeLatest(AppealActions.UploadAppealAttachments.init, uploadAllAppealAttachments),
    takeLatest(AppealActions.DeleteAppealAttachment.init, deleteAppealAttachment),
    takeLatest(AppealActions.changeAppealType, changeAppealTypeWorker),
    takeLatest(AppealActions.openSelectMasterDialog, openSelectMasterDialogWorker),
    takeLatest(AppealActions.openWorkPlanDialog, openWorkPlanDialogWorker),
    takeLatest(AppealActions.GetAppealCalls.init, getAppealCalls),
    takeLatest(AppealActions.openSelectSupplierDialog, openSelectSupplierDialogWorker),
    takeLatest(AppealActions.openAssignIncidentsDialog, openAssignIncidentsDialogWorker),
    takeLatest(AppealActions.ChangeAppealSupplier.init, changeAppealSupplierWorker),
    takeLatest(AppealActions.NavigateToAppealPage, navigateToAppealPageWorker),
    takeLatest([AppealActions.GetAppealTypes.init, AppealsActions.GetAppealDictionaries], getAppealTypesWorker),
    takeLatest([AppealActions.GetAppealCategories.init, AppealsActions.GetAppealDictionaries], getAppealCategoriesWorker),
    takeLatest([AppealActions.GetAppealKinds.init, AppealsActions.GetAppealDictionaries], getAppealKindsWorker),
    takeLatest([AppealActions.GetAppealStatuses.init, AppealsActions.GetAppealDictionaries], getAppealStatusesWorker),
    takeLatest([AppealActions.GetAppealAdditionalFields.init, AppealsActions.GetAppealDictionaries], getAppealAdditionalFieldsWorker),
    takeLatest([AppealsActions.GetAppealWorks.init, AppealActions.GetAppealDictionaries], getAppealWorksWorker),
    takeLatest(AppealActions.GetAppealMessages.init, getAppealMessagesWorker),
    takeLatest(AppealsActions.SetReadAppealMessages.init, setReadAppealMessagesWorker),
    takeLatest(AppealActions.CreateAppealMessage.init, createAppealMessageWorker),
    takeLatest(AppealActions.GetAppealChangesHistory.init, getAppealChangesHistory),
  ]);
}
