import React from 'react';
import { HeaderSmall } from '../../../../../typography/text';
import { DialogContentProps } from './types';
import { MailingStatus } from '../../../../../models/mailing';

export const DialogHeader = React.memo(({ mailing }: DialogContentProps) => {
  const isOldMailing = mailing?.status === MailingStatus.Error || mailing?.status === MailingStatus.Sended;

  if (mailing) {
    return (
      <div>
        <HeaderSmall>{isOldMailing ? 'Просмотр оповещения' : 'Редактировать оповещение'}</HeaderSmall>
        <br />
      </div>
    );
  }

  return <HeaderSmall>Новое оповещение</HeaderSmall>;
});
