import { TableCommonStructureDto, TablesConfigurationDto } from '../../../models/configuration';
import { TableCommonStructure, TableConfiguration } from '../../../../store/configuration/models/get-appeals-configuration-success.payload';

const transformCommonStructure = (dto: TableCommonStructureDto): TableCommonStructure => {
  if (!Array.isArray(dto.sideFilters || dto.inlineFilters)) {
    throw new Error('Invalid TableCommonStructureDto: sideFilters must be an array');
  }

  return {
    columns: dto.columns,
    filters: {
      inlineFilters: dto.inlineFilters,
      sideFilters: dto.sideFilters,
    },
  };
};
export const configurationDtoMapper = (dto: TablesConfigurationDto): TableConfiguration => ({
  appeals: {
    appealTypes: dto.appeals.appealTypes,
    tableStructures: Object.keys(dto.appeals.tableStructures).reduce((acc, key) => {
      acc[key] = transformCommonStructure(dto.appeals.tableStructures[key]);
      return acc;
    }, {} as Record<string, TableCommonStructure>),
    appealActions: dto.appeals.appealActions,
    appealRequisitionKindOptions: dto.appeals.appealRequisitionKindOptions,
  },
  calls: transformCommonStructure(dto.calls),
  consumers: transformCommonStructure(dto.consumers),
  managementCompanies: transformCommonStructure(dto.management_companies),
  masters: transformCommonStructure(dto.masters),
  mailing: transformCommonStructure(dto.mailing),
  incidents: transformCommonStructure(dto.incidents),
  speechNotifications: transformCommonStructure(dto.speech_notifications),
});
