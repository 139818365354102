import { createSlice } from '@reduxjs/toolkit';
import { Master } from 'app/models/master';
import { Option } from 'app/models/common/option';
import { ChosenFilters } from 'app/models/common/chosen-filters';
import { getChosenFilter, resetSomeFilters } from 'app/helpers/filters/get-chosen-filter';
import { getNewFilterValue } from 'app/helpers/filters/get-filter-new-value';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import { MASTERS_STORE_NAME } from './masters.actions';
import * as MastersActions from './masters.actions';

export interface MastersState extends EntityState {
  masters: Master[];
  mastersTotalCount: number;
  chosenFilters: ChosenFilters;
  mastersPositions: Option<number>[];

  error: any;
}

export const mastersInitialState: MastersState = {
  masters: [],
  mastersTotalCount: 0,
  chosenFilters: {},
  mastersPositions: [],
  error: {},

  ...initialEntityState,
};

const mastersSlice = createSlice({
  name: MASTERS_STORE_NAME,
  initialState: mastersInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(MastersActions.LoadMasters.init, state => {
        state.loading = true;
      })
      .addCase(MastersActions.LoadMasters.success, (state, { payload }) => {
        state.loading = false;
        if (!payload.masters) return;
        state.masters = payload.page === 1 ? payload.masters : state.masters.concat(payload.masters);
        state.mastersTotalCount = payload.mastersTotalCount;
      })
      .addCase(MastersActions.LoadMasters.failure, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(MastersActions.CreateMaster.init, state => {
        state.creating = true;
      })
      .addCase(MastersActions.CreateMaster.success, state => {
        state.creating = false;
      })
      .addCase(MastersActions.CreateMaster.failure, (state, { payload }) => {
        state.error = payload;
        state.creating = false;
      })
      .addCase(MastersActions.UpdateMaster.init, state => {
        state.updating = true;
      })
      .addCase(MastersActions.UpdateMaster.success, state => {
        state.updating = false;
      })
      .addCase(MastersActions.UpdateMaster.failure, (state, { payload }) => {
        state.error = payload;
        state.updating = false;
      })
      .addCase(MastersActions.setChosenFilter, (state, { payload }) => {
        state.chosenFilters = getChosenFilter(payload.filters.inlineFilters, payload.filters.sideFilters);
      })
      .addCase(MastersActions.changeChosenFilter, (state, { payload }) => {
        state.chosenFilters[payload.key] = getNewFilterValue(payload.value);
      })
      .addCase(MastersActions.GetMastersPositions.init, state => {
        state.loading = true;
      })
      .addCase(MastersActions.GetMastersPositions.success, (state, { payload }) => {
        state.mastersPositions = payload;
        state.loading = false;
      })
      .addCase(MastersActions.GetMastersPositions.failure, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(MastersActions.DeleteMaster.init, state => {
        state.deleting = true;
      })
      .addCase(MastersActions.DeleteMaster.success, (state, { payload }) => {
        state.masters = state.masters.filter(master => master.id !== payload.id);
        state.deleting = false;
      })
      .addCase(MastersActions.DeleteMaster.failure, (state, { payload }) => {
        state.error = payload;
        state.deleting = false;
      })
      .addCase(MastersActions.resetSomeFilters, (state, { payload }) => {
        state.chosenFilters = resetSomeFilters(state.chosenFilters, payload);
      }),
});

export const masterReducer = mastersSlice.reducer;
