import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../types/AppState.type';

const selectConsumersState = (appState: AppState) => appState.consumersState;

export const selectConsumers = createSelector(selectConsumersState, state => state.consumers);

export const selectChosenFilters = createSelector(selectConsumersState, state => state.chosenFilters);

export const selectConsumersAreLoading = createSelector(selectConsumersState, state => state.consumersAreLoading);

export const selectConsumersLoadingError = createSelector(selectConsumersState, state => state.consumersLoadingError);

export const selectSelectedConsumer = createSelector(selectConsumersState, state => state.selectedConsumer);

export const selectSelectedConsumerIsLoading = createSelector(selectConsumersState, state => state.selectedConsumerIsLoading);

export const selectSelectedConsumerLoadingError = createSelector(selectConsumersState, state => state.selectedConsumerLoadingError);

export const selectConsumerAppeals = createSelector(selectConsumersState, state => state.appeals);

export const selectAreConsumerAppealsLoading = createSelector(selectConsumersState, state => state.areAppealsLoading);
export const selectConsumerAppealsTotalCount = createSelector(selectConsumersState, state => state.appealsTotalCount);

export const selectConsumerAppealsLoadingError = createSelector(selectConsumersState, state => state.appealsLoadingError);

export const selectConsumerCalls = createSelector(selectConsumersState, state => state.calls);
export const selectConsumerCallsTotalCount = createSelector(selectConsumersState, state => state.callsTotalCount);

export const selectAreConsumerCallsLoading = createSelector(selectConsumersState, state => state.areCallsLoading);

export const selectConsumerCallsLoadingError = createSelector(selectConsumersState, state => state.callsLoadingError);

export const selectConsumersTotalCount = createSelector(selectConsumersState, state => state.consumersTotalCount);

export const selectIsFiltered = createSelector(selectConsumersState, state => {
  // eslint-disable-next-line
  const { fullName__icontains, ...filters } = state.chosenFilters;
  return Object.values(filters).filter(Boolean).length > 0;
});

export const selectConsumerCreatingError = createSelector(selectConsumersState, state => {
  const error = state.consumersCreatingError?.data;

  if (!error) return null;

  if ('message' in error) return error.message;

  return error?.data;
});

export const consumerAppealsByAddressId = createSelector(
  [
    selectConsumerAppeals, // Селектор, который возвращает все обращения
    (_, addressId: string | undefined) => addressId, // Функция, которая принимает состояние и возвращает идентификатор адреса
  ],
  (appeals, addressId) => {
    return appeals.filter(appeal => appeal.address?.id === addressId);
  }
);

export const selectConsumerAppealsByAddressId = (addressId: string | undefined) => (state: AppState) =>
  consumerAppealsByAddressId(state, addressId);
