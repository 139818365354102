import React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import history from 'app/history';
import { RoutePath } from 'app/constants/route-path';
import { Row } from 'react-table';
import { isOverdue } from 'app/helpers/date/is-overdue';
import { ConfigurationSelectors } from 'store/configuration';
import { Appeal } from 'app/models/appeal';
import { APPEAL_STATUS } from 'app/constants/appeals';
import { EmptyTable, FileIcon, Text } from 'app/components/Tabs/constants/elements';
import { SuppliersActions, SuppliersSelectors } from 'store/suppliers';
import { AppealsTable, CLOSED_APPEAL_ROW_CLASS_NAME, OVERDUE_APPEAL_ROW_CLASS_NAME } from './styled';
import { APPEAL_HISTORY_COLUMNS, ConsumerAppealHistoryCell } from '../../../../components/Tabs/constants/appeal-history.columns';

interface AppealsHistoryTabProps {
  supplierId: string | undefined;
}

export const AppealsHistoryTab = React.memo<AppealsHistoryTabProps>(props => {
  const dispatch = useDispatch();

  const appealAssignDelay = useSelector(ConfigurationSelectors.selectAppealsAcceptingDelay, shallowEqual);
  const appeals = useSelector(SuppliersSelectors.selectSelectedSupplierAppeals, shallowEqual);
  const totalCount = useSelector(SuppliersSelectors.selectSelectedSupplierAppealsTotalCount, shallowEqual);
  const isLoading = useSelector(SuppliersSelectors.selectSupplierAppealsLoading);

  const [page, setPage] = React.useState(1);

  const cellHandleClick = cell => {
    const appeal = cell.row.original as Appeal;
    if (cell.column.id !== ConsumerAppealHistoryCell.Actions) {
      history.push(`${RoutePath.Appeals}/${appeal.id}`);
    }
  };

  const handleNextPageLoad = React.useCallback(() => {
    setPage(prevState => prevState + 1);
  }, []);

  const getRowClassName = (row: Row<Appeal>) => {
    const appeal = row.original;
    if (appeal.status === APPEAL_STATUS.closed || appeal.status === APPEAL_STATUS.canceled) return CLOSED_APPEAL_ROW_CLASS_NAME;
    if (appeal.status === APPEAL_STATUS.new && isOverdue(appeal.createdAt, appealAssignDelay)) {
      return OVERDUE_APPEAL_ROW_CLASS_NAME;
    }
    return '';
  };

  React.useEffect(() => {
    if (!props.supplierId) return;
    dispatch(
      SuppliersActions.LoadSupplierAppeals.init({
        id: props.supplierId,
        page,
      })
    );
  }, [dispatch, page, props.supplierId]);

  if (!appeals.length) {
    return (
      <EmptyTable>
        <FileIcon icon="/assets/icons.svg#file" />
        <Text>В истории нет обращений</Text>
      </EmptyTable>
    );
  }

  return (
    <AppealsTable
      columns={APPEAL_HISTORY_COLUMNS}
      nextPage={handleNextPageLoad}
      data={appeals}
      onCellClick={cellHandleClick}
      rowClassName={getRowClassName}
      isLoading={isLoading && page === 1}
      dataLength={totalCount}
      isInfinity
    />
  );
});
