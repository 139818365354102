import { all, call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { SuppliersService } from 'app/services/suppliers-service/suppliers.service';
import { Supplier, SupplierId } from 'app/models/supplier';
import { DefaultQueryParams } from 'app/models/common/default-query-params';
import { TableDto } from 'app/services/models/table.dto';
import { DEFAULT_ROWS_COUNT, MAX_ROWS_COUNT } from 'app/constants/scroll';
import { SuppliersActions } from 'store/suppliers';
import { AppealsService } from 'app/services/appeals-service/appeals.service';
import { Appeal } from 'app/models/appeal';
import Axios from 'axios';
import { PaginationQueryParams } from '../../app/models/common';

function* getTableSuppliersWatcher({ payload }: PayloadAction<DefaultQueryParams>) {
  try {
    const response: TableDto<Supplier> = yield call(SuppliersService.getPaginationSuppliers, {
      per_page: payload.per_page || DEFAULT_ROWS_COUNT,
      page: payload.page || 1,
      ...payload,
    });

    yield put(
      SuppliersActions.GetSuppliersForTable.success({
        suppliers: response.results,
        suppliersTotalCount: response.count,
        page: response.page,
      })
    );
  } catch (e: any) {
    yield put(SuppliersActions.GetSuppliersForTable.failure(e));
  }
}

function* getSuppliersWorker() {
  try {
    const response = yield call(SuppliersService.getSuppliers);

    yield put(SuppliersActions.GetSuppliers.success(response));
  } catch (e) {
    yield put(SuppliersActions.GetSuppliers.failure(e));
  }
}

function* getSupplierByIdWatcher({ payload }: PayloadAction<string>) {
  try {
    const response = yield call(SuppliersService.getSupplierById, payload);

    yield put(SuppliersActions.GetSupplierById.success(response));
  } catch (e) {
    yield put(SuppliersActions.GetSupplierById.failure(e));
  }
}

function* getSupplierCallsWorker({ payload }: PayloadAction<{ id: SupplierId } & PaginationQueryParams>) {
  try {
    const response = yield call(SuppliersService.getSupplierCalls, {
      per_page: payload.per_page || MAX_ROWS_COUNT,
      ...payload,
    });

    yield put(SuppliersActions.LoadSupplierCalls.success(response));
  } catch (e) {
    yield put(SuppliersActions.LoadSupplierCalls.failure(e));
  }
}

function* loadSupplierAppealsWorker({ payload }: PayloadAction<{ id: string } & PaginationQueryParams>) {
  const cancelSource = Axios.CancelToken.source();
  try {
    const response: TableDto<Appeal> = yield call(
      AppealsService.getAppeals,
      {
        managementCompany: payload.id,
        per_page: payload.per_page || DEFAULT_ROWS_COUNT,
        page: payload.page,
      },
      cancelSource.token
    );
    yield put(SuppliersActions.LoadSupplierAppeals.success(response));
  } catch (e: any) {
    yield put(SuppliersActions.LoadSupplierAppeals.failure(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(SuppliersActions.GetSuppliers.init, getSuppliersWorker),
    takeLatest(SuppliersActions.GetSuppliersForTable.init, getTableSuppliersWatcher),
    takeLatest(SuppliersActions.GetSupplierById.init, getSupplierByIdWatcher),
    takeLatest(SuppliersActions.LoadSupplierCalls.init, getSupplierCallsWorker),
    takeLatest(SuppliersActions.LoadSupplierAppeals.init, loadSupplierAppealsWorker),
  ]);
}
