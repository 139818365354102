import { TablesConfigurationDto } from 'app/models/configuration';
import { HttpService } from '../http.service';
import { TableConfiguration } from '../../../store/configuration/models/get-appeals-configuration-success.payload';
import { configurationDtoMapper } from './dtos/configuration-dto.mapper';

class ConfigurationServiceImpl {
  private static apiEndpoint = '/api/configuration';

  getTablesConfiguration(): Promise<TableConfiguration> {
    return HttpService.get<TablesConfigurationDto>(`${ConfigurationServiceImpl.apiEndpoint}/tables`).then(response =>
      configurationDtoMapper(response.data)
    );
  }
}

export const ConfigurationService = new ConfigurationServiceImpl();
