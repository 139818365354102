import { TableDto } from 'app/services/models/table.dto';
import { getCreateActionTrio } from '../helpers/action-create';
import { Incident, IncidentId } from '../../app/models/incident/incident';
import { CreateIncidentApiParams } from '../../app/services/incidents-service/dtos/create-incident.request';
import { IncidentsQueryParams } from '../../app/services/incidents-service/dtos/incidents-query-params';
import { CommonFiltersStructure } from '../../app/models/configuration';
import { createPayloadAction } from '../configuration/configuration.actions';
import { EditIncidentApiParams } from '../../app/services/incidents-service/dtos/edit-incident';
import {
  AddAppealsToIncidentApiParams,
  AddAppealsToIncidentApiResponse,
} from '../../app/services/incidents-service/dtos/add-appeals-to-incident';
import { GetRelatedAppealsApiParams, GetRelatedAppealsApiResponse } from '../../app/services/incidents-service/dtos/get-related-appeals';
import { ChangeChosenFilterPayload } from '../models/change-chosen-filter-payload';

export const INCIDENTS_STORE_NAME = 'incidents';

export const createActionTrio = getCreateActionTrio(INCIDENTS_STORE_NAME);

export const GetIncidents = createActionTrio<IncidentsQueryParams | void, TableDto<Incident>>('get incidents');

export const CreateIncident = createActionTrio<CreateIncidentApiParams, Incident>('create incident');
export const GetIncidentById = createActionTrio<IncidentId, Incident>('get one incident');
export const EditIncident = createActionTrio<EditIncidentApiParams, Incident>('edit incident');
export const AddAppealsToIncident = createActionTrio<AddAppealsToIncidentApiParams, AddAppealsToIncidentApiResponse>(
  'add appeals to incident'
);
export const GetRelatedAppeals = createActionTrio<GetRelatedAppealsApiParams, GetRelatedAppealsApiResponse>('get related appeals');

export const setIncidentsChosenFilters = createPayloadAction<{ filters: CommonFiltersStructure }>('set incidents chosen filter');

export const changeIncidentsChosenFilter = createPayloadAction<ChangeChosenFilterPayload>('change incidents chosen filter');

export const resetIncidentsSomeFilters = createPayloadAction<string[]>('reset some incidents filters');
