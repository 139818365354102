import {
  Appeal,
  AppealAdditionalFields,
  AppealChangesHistory,
  AppealDictionary,
  AppealId,
  AppealMessage,
  AppealWork,
  GetAppealCallsApiParams,
} from 'app/models/appeal';
import { AppealsQueryParams } from 'app/models/appeal/appeals-query-params';
import { Option } from 'app/models/common/option';
import { UpdateAppealRequest } from 'app/services/appeals-service/dtos/update-appeal-request';
import { AddFeedbackDto } from 'app/services/appeals-service/dtos/add-feedback-dto';
import { ChangeAppealSupplierDto } from 'app/services/appeals-service/dtos/change-appeal-supplier-request';
import { AppealAttachment } from 'app/models/appeal/appeal-attachment';
import { TableDto } from 'app/services/models/table.dto';
import { GetAppealWorkPricesRequest } from 'app/services/appeals-service/dtos/get-appeal-work-prices-request';
import { Call } from 'app/models/call';
import { getCreateActionFromStore, getCreateActionTrio, getCreatePayloadActionFromStore } from '../helpers/action-create';
import { ChangeChosenFilterPayload } from '../models/change-chosen-filter-payload';
import { UploadAppealAttachmentsPayload } from './models/upload-appeal-attachments.payload';
import { CreateAppealPayload } from './models/create-appeal.payload';
import { TableCommonStructure } from '../configuration/models/get-appeals-configuration-success.payload';

export const APPEALS_STORE_NAME = 'appeals';

const createAction = getCreateActionFromStore(APPEALS_STORE_NAME);
const createPayloadAction = getCreatePayloadActionFromStore(APPEALS_STORE_NAME);
const createActionTrio = getCreateActionTrio(APPEALS_STORE_NAME);

export const GetAppeals = createActionTrio<AppealsQueryParams, TableDto<Appeal>>('appeals');
export const GetAppealsCreatedGte = createActionTrio<AppealsQueryParams, TableDto<Appeal>>('load new  appeals');

export const GetAppealById = createActionTrio<{ id: number }, Appeal>('get appeal by id');

export const NavigateToUpdateAppealPage = createActionTrio<{ id: number }, void>('navigate to update appeal');

export const CreateAppeal = createActionTrio<CreateAppealPayload, Appeal>('create appeal');

export const changeAppealType = createPayloadAction<{ type: string; types: Option<string>[] }>('change appeal type');

export const UpdateAppeal = createActionTrio<UpdateAppealRequest, Appeal>('update appeal');

export const EditAppealIncidents = createActionTrio<UpdateAppealRequest, Appeal>('edit incident appeals');

export const DeleteAppeal = createActionTrio<{ id: number }, { id: number }>('delete appeal');

export const CloseAppeal = createActionTrio<AddFeedbackDto, Appeal>('close appeal');

export const GetAppealTypes = createActionTrio<void, AppealDictionary[]>('Get appeal types');
export const GetAppealCategories = createActionTrio<void, AppealDictionary[]>('Get appeal categories');
export const GetAppealKinds = createActionTrio<void, AppealDictionary[]>('Get appeal kinds');
export const GetAppealStatuses = createActionTrio<void, AppealDictionary[]>('Get appeal statuses');
export const GetAppealAdditionalFields = createActionTrio<void, AppealAdditionalFields>('Get appeal additional fields');
export const GetAppealWorks = createActionTrio<GetAppealWorkPricesRequest | undefined, AppealWork[]>('Get appeal works');
export const GetAppealDictionaries = createAction('Get appeal dictionaries');

export const openAddFeedbackDialog = createPayloadAction<{ appeal: Appeal }>('open close appeal dialog');

export const AssignMaster = createActionTrio<UpdateAppealRequest, Appeal>('assign master');

export const setChosenFilter = createPayloadAction<string>('set chosen filter');

export const setFilters = createPayloadAction<Record<string, TableCommonStructure>>('set filters');
export const changeChosenFilter = createPayloadAction<ChangeChosenFilterPayload>('change chosen filter');

export const resetSomeFilters = createPayloadAction<string[]>('reset some filters');

export const ChangeAppealStatus = createActionTrio<{ id: number; status: string }, Appeal>('change appeal status');

export const UploadAppealAttachments = createActionTrio<UploadAppealAttachmentsPayload, AppealAttachment>('upload appeal attachment');

export const DeleteAppealAttachment = createActionTrio<string, string>('delete appeal attachment');

export const openSelectMasterDialog = createPayloadAction<{ appeal: Appeal }>('open close select master dialog');

export const openWorkPlanDialog = createPayloadAction<{ appeal: Appeal }>('open close work plan dialog');

export const GetAppealCalls = createActionTrio<GetAppealCallsApiParams, TableDto<Call>>('get appeal calls');

export const openSelectSupplierDialog = createPayloadAction<{ appealId: number }>('open close select supplier dialog');
export const openAssignIncidentsDialog = createPayloadAction<void>('open close assign incidents dialog');

export const ChangeAppealSupplier = createActionTrio<ChangeAppealSupplierDto, number>('change appeal management company');

export const NavigateToAppealPage = createPayloadAction<{ id: number }>('navigate to appeal page');

export const GetAppealMessages = createActionTrio<{ id: number }, AppealMessage[]>('get appeal messages');
export const SetReadAppealMessages = createActionTrio<{ id: number }, void>('set read appeal messages');
export const CreateAppealMessage = createActionTrio<{ id: number; text: string }, AppealMessage>('create appeal message');

export const SetAppealsStatus = createPayloadAction<string | undefined>('set appeals active status');

export const GetAppealChangesHistory = createActionTrio<{ id: AppealId }, AppealChangesHistory[]>('get appeal changes history');
