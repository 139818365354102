import { ModalProps } from 'app/services/modal-service/modal.service';
import { useDispatch } from 'react-redux';
import * as AppealsActions from 'store/appeals/appeals.actions';
import React from 'react';
import { Textarea } from 'app/components/shared';
import { HeaderSmall } from 'app/typography/text';
import { CenteredFlexWithSpacing, ColumnFlexWithPadding } from 'app/typography/flex';
import { Colors } from 'app/themes/colors';
import { AnyAction } from '@reduxjs/toolkit';
import { Appeal } from 'app/models/appeal';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import { CloseAppealButton, CloseAppealDialogComponent, EvaluationIcon } from './styled';

const STARS_COUNT = 5;

interface AddFeedbackDialogProps extends ModalProps {
  appeal: Appeal;
}

export const AddFeedbackDialog = ({ onClose = () => {}, appeal }: AddFeedbackDialogProps) => {
  const dispatch = useDispatch();

  const [evaluation, setEvaluation] = React.useState<number>(0);
  const [hoverStar, setHoverStar] = React.useState<number>(0);
  const [comment, setComment] = React.useState<string>('');

  const getIconColor = React.useCallback(
    (index: number) => {
      return index < hoverStar || index < evaluation ? Colors.Blue800 : Colors.Grey700;
    },
    [evaluation, hoverStar]
  );

  const onSaveClick = React.useCallback(() => {
    if (!appeal?.id) return;

    const params = {
      id: appeal?.id,
      comment: comment || null,
      evaluation: evaluation || null,
    };

    dispatch(AppealsActions.CloseAppeal.init(params));
  }, [appeal?.id, comment, dispatch, evaluation]);

  useActionListener((action: AnyAction) => {
    switch (action.type) {
      case AppealsActions.CloseAppeal.success.type:
        onClose();
    }
  });

  return (
    <CloseAppealDialogComponent
      headerContent={<HeaderSmall>Закрыть заявку</HeaderSmall>}
      body={
        <ColumnFlexWithPadding spacing="30px">
          <CenteredFlexWithSpacing spacing="15px">
            {new Array(STARS_COUNT).fill(1).map((_, index) => (
              <EvaluationIcon
                disabled={false}
                color={getIconColor(index)}
                icon="/assets/icons.svg#star"
                onClick={() => setEvaluation(index + 1)}
                onMouseEnter={() => setHoverStar(index + 1)}
                onMouseLeave={() => setHoverStar(0)}
              />
            ))}
          </CenteredFlexWithSpacing>
          <ColumnFlexWithPadding spacing="20px">
            <Textarea value={comment} onChange={e => setComment(e.target.value)} />
          </ColumnFlexWithPadding>
          <CloseAppealButton onClick={onSaveClick}>Сохранить</CloseAppealButton>
        </ColumnFlexWithPadding>
      }
      onClose={onClose}
    />
  );
};
