import { AppState } from 'types/AppState.type';
// eslint-disable-next-line import/no-extraneous-dependencies
import createCachedSelector from 're-reselect';
import { ServicesState } from './services.reducer';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';

const selectServicesState: (state: AppState) => ServicesState = state => state.servicesState;

const servicesOptionsSelector = (() => {
  const selector = createCachedSelector([selectServicesState], services => {
    if (!services?.services.length) return [];
    return services?.services?.map(service => ({
      key: service.id,
      value: service.id,
      label: service.name,
    }));
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectServicesOptions = (state: AppState) => servicesOptionsSelector(state);
