import { differenceInDays, differenceInHours, differenceInMinutes, format, isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import { AppealRoadMap } from '../../../../models/appeal';

export const formatDateHelper = (date: string, createdAt: string, isAlwaysFull: boolean): string => {
  if (!date) return 'Не назначено';

  const dateObj = new Date(date);
  const createdAtObj = new Date(createdAt);

  if (isAlwaysFull || !isSameDay(dateObj, createdAtObj)) {
    return format(dateObj, 'dd.MM.yyyy HH:mm', { locale: ru });
  }
  return format(dateObj, 'HH:mm', { locale: ru });
};

export const calculateTimeDifference = (date1: string, date2: string) => {
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);

  const days = differenceInDays(date2Obj, date1Obj);
  const hours = differenceInHours(date2Obj, date1Obj) % 24;
  const minutes = differenceInMinutes(date2Obj, date1Obj) % 60;

  const daysStr = days > 0 ? `${days}д ` : '';
  const hoursStr = hours > 0 ? `${hours}ч ` : '';
  const minutesStr = minutes > 0 ? `${minutes}м ` : '';

  return `${daysStr}${hoursStr}${minutesStr}`.trim();
};

export const findNextAvailableDate = (roadmap: AppealRoadMap[], currentIndex: number): string | undefined => {
  const allDates: string[] = [];

  // Пробегаем по всем последующим элементам массива roadmap начиная с текущего индекса
  for (let i = currentIndex + 1; i < roadmap.length; i++) {
    const currentRoadmapItem = roadmap[i];

    if (!currentRoadmapItem) continue;

    // Сначала добавляем основную дату (created_on)
    if (currentRoadmapItem.created_on) {
      allDates.push(currentRoadmapItem.created_on);
    }

    if (currentRoadmapItem.fields) {
      Object.values(currentRoadmapItem.fields)
        .filter(
          (
            field
          ): field is {
            type: string;
            value: string;
          } => typeof field === 'object' && 'type' in field && 'value' in field
        )
        .filter(field => field.type === 'datetime')
        .forEach(({ value }) => {
          allDates.push(value);
        });
    }
  }
  // Если в allDates есть хотя бы одна дата, возвращаем первую найденную
  return allDates.length > 0 ? allDates[0] : undefined;
};

export const transformFields = (fields: Record<string, any> | null): { name: string; value: string; responsible: string }[] => {
  if (!fields) return []; // Если fields пустой, возвращаем пустой массив
  return Object.values(fields)
    .filter(field => field.type === 'datetime') // Фильтруем по type: 'datetime'
    .map(({ name, value }) => ({ name, value, responsible: '' }));
};
