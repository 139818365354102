import { Brand, Nullable, PartialNullable } from '../../../utils/types/types.common';
import { Profile } from '../profile/profile';
import { Supplier } from '../supplier';
import { Master } from '../master';
import { AddressesData } from '../../pages/Mailings/components/AddressesList';
import { Appeal } from '../appeal';
import { Mailing } from '../mailing';

export type IncidentId = Brand<number, 'IncidentId'>;

export type Incident = { id: IncidentId } & Nullable<{
  created_at: string;
  number: string;
  appeal_kind: string;
  incident_type: 'accident' | 'scheduled_work';
  addresses: AddressesData[];
  author: Pick<Profile, 'id' | 'fullName' | 'phone'>;
  responsible: Pick<Profile, 'id' | 'fullName' | 'phone'>;
  supplier: PartialNullable<Supplier>;
  master: Pick<Master, 'id' | 'fullName' | 'phone'>;
  status: IncidentStatus | undefined;
  text: string;
  planned_start_date: string;
  planned_end_date: string;
  actual_due_date: string;
  appeals_count: number;
  appeals: Array<Partial<Appeal>>;
  pushnotification: Pick<Mailing, 'id' | 'status'> | null;
}>;

export enum IncidentStatus {
  New = 'new',
  InProgress = 'in_progress',
  Completed = 'completed',
  Canceled = 'canceled',
}

export enum IncidentType {
  Accident = 'accident',
  ScheduledWork = 'scheduled_work',
}

export const IncidentTypeToLabelMap = {
  [IncidentType.Accident]: 'Авария',
  [IncidentType.ScheduledWork]: 'Плановые работы',
  [IncidentStatus.Completed]: 'Завершенные',
  [IncidentStatus.Canceled]: 'Отмененные',
};

export const IncidentTabStatusToLabelMap = {
  [IncidentStatus.New]: 'Новые',
  [IncidentStatus.InProgress]: 'В работе',
  [IncidentStatus.Completed]: 'Завершенные',
  [IncidentStatus.Canceled]: 'Отмененные',
};
export const IncidentLabelStatusToLabelMap = {
  [IncidentStatus.New]: 'Новый',
  [IncidentStatus.InProgress]: 'В работе',
  [IncidentStatus.Completed]: 'Завершен',
  [IncidentStatus.Canceled]: 'Отменен',
};
