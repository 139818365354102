export const getDeclension = (number: number, singular: string, dual: string, plural: string): string => {
  const remainder10 = number % 10;
  const remainder100 = number % 100;

  if (remainder100 >= 11 && remainder100 <= 19) {
    return plural;
  }

  if (remainder10 === 1) {
    return singular;
  }

  if (remainder10 >= 2 && remainder10 <= 4) {
    return dual;
  }

  return plural;
};
