import React from 'react';
import styled from 'styled-components';
import { SuppliersActions, SuppliersSelectors } from 'store/suppliers';
import { shallowEqual, useSelector } from 'react-redux';
import { CenteredFlexWithSpacing, ColumnFlexWithPadding, VerticallyCenteredFlexWithSpaceBetween } from 'app/typography/flex';
import { HeaderBig } from 'app/typography/text';
import { DefaultFilterKey } from 'app/models/common/query-filters';
import { FilterButton, Input, SideMenuFilter } from 'app/components/shared';
import { useAction } from 'app/helpers/actions/use-action';
import { useFilters } from 'app/helpers/hooks/use-filters';
import { SideModalService } from 'app/services/modal-service/modal.service';
import { KeyCode } from 'app/constants/key-code';
import { ConfigurationSelectors } from 'store/configuration';

interface SuppliersFiltersProps {
  setIsSearchFocus: (focus: boolean) => void;
  setIsSideMenuOpen: (open: boolean) => void;
}

export const SuppliersFilters = ({ setIsSearchFocus, setIsSideMenuOpen }: SuppliersFiltersProps) => {
  const filters = useSelector(ConfigurationSelectors.selectSuppliersFilters, shallowEqual);
  const chosenFilters = useSelector(SuppliersSelectors.selectChosenFilters);
  const isFiltered = useSelector(SuppliersSelectors.selectIsFiltered);

  const { getValue, onFilterChange } = useFilters({
    chosenFilters,
    onFilterChangeAction: SuppliersActions.GetSuppliersForTable.init,
  });

  const changeChosenFilter = useAction(SuppliersActions.changeChosenFilter);

  const openSideMenu = () => {
    setIsSideMenuOpen(true);
    SideModalService.openModal(SideMenuFilter, {
      filtersConfig: filters.sideFilters,
      selectorChosenFilters: SuppliersSelectors.selectChosenFilters,
      changeFilterAction: SuppliersActions.changeChosenFilter,
      applyChangeFilterAction: SuppliersActions.GetSuppliersForTable.init,
      resetFilterAction: SuppliersActions.resetSomeFilters,
      onClose: () => setIsSideMenuOpen(false),
    });
  };

  const handleKeyPress = event => {
    if (event.charCode === KeyCode.Enter) {
      onFilterChange();
    }
  };

  return (
    <SuppliersFilterComponent spacing="1rem">
      <VerticallyCenteredFlexWithSpaceBetween>
        <HeaderBig>Контрагенты</HeaderBig>
        <CenteredFlexWithSpacing spacing="1rem">
          <Search
            search
            noError
            placeholder="Поиск по названию"
            onChange={e => {
              changeChosenFilter({
                key: DefaultFilterKey.SuppliersSearch,
                value: e.target.value || undefined,
              });
            }}
            value={getValue(DefaultFilterKey.SuppliersSearch)}
            onKeyPress={handleKeyPress}
            onFocus={() => setIsSearchFocus(true)}
            onBlur={() => setIsSearchFocus(false)}
          />

          <FilterButton isFiltered={isFiltered} onClick={openSideMenu} />
        </CenteredFlexWithSpacing>
      </VerticallyCenteredFlexWithSpaceBetween>
    </SuppliersFilterComponent>
  );
};

const SuppliersFilterComponent = styled(ColumnFlexWithPadding)`
  padding: 1.25rem 1.875rem;
`;

const Search = styled(Input)`
  width: 25rem;

  input {
    padding: 12px 2.8125rem;
  }
`;
