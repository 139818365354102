import React from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { ActionButton, ConfirmDialog, Title } from './styled';
import { useLockBodyScroll } from '../../../helpers';
import { DialogProps } from '../../shared';
import { TextRegular } from '../../../typography/text';
import { FlexWithSpacing } from '../../../typography/flex';
import { useActionListener } from '../../../helpers/actions/action-listener.hook';
import { UpdateAppeal } from '../../../../store/appeals/appeals.actions';
import { DeleteMailing } from '../../../../store/mailings/mailings.actions';

type ConfirmModalProps = {
  title: string;
  text: string;
  onClick: () => void;
  textButton: string;
} & Pick<DialogProps, 'onClose'>;

export const ConfirmModal = (props: ConfirmModalProps) => {
  useActionListener((action: AnyAction) => {
    if (action.type === UpdateAppeal.success.type || action.type === DeleteMailing.success.type) {
      props.onClose();
    }
  });

  const body = React.useMemo(() => {
    return (
      <>
        <Title>{props.title}</Title>
        <TextRegular>{props.text}</TextRegular>
        <FlexWithSpacing spacing="15px">
          <ActionButton onClick={props.onClick}>{props.textButton}</ActionButton>
          <ActionButton onClick={props.onClose} mod="secondary">
            Отмена
          </ActionButton>
        </FlexWithSpacing>
      </>
    );
  }, [props.onClick, props.onClose, props.text, props.textButton, props.title]);

  useLockBodyScroll();

  return <ConfirmDialog onClose={props.onClose} body={body} />;
};
