import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';
import { Column } from 'react-table';
import { AppState } from '../../types/AppState.type';
import * as AppealsSelector from '../appeals/appeals.selectors';
import { ConfigurationState } from './configuration.reducer';
import { getColumnsFromAppealsTableStructure, getValueFromAppealsTableStructure } from './helpers/get-value-from-appeals-table-structure';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';
import { TableColumn } from '../../app/models/configuration';
import { SpeechNotification } from '../../app/models/speech-notification';
import {
  getCustomCell,
  getLabel,
  isTableCustomColumn,
} from '../../app/pages/SpeechNotifications/components/SpeechNotificationsTable/helpers';
import { TableCommonStructure } from './models/get-appeals-configuration-success.payload';

export const selectConfigurationState: (state: AppState) => ConfigurationState = state => state.configurationState;

export const selectAppealTypes = createSelector(selectConfigurationState, state => state.tables?.appeals.appealTypes || []);

const appealsColumnsSelector = (() => {
  const selector = createCachedSelector([selectConfigurationState, AppealsSelector.selectCurrentAppealType], (state, appealType) => {
    return getColumnsFromAppealsTableStructure('columns', state.tables?.appeals.tableStructures, appealType?.value);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectAppealColumns = (state: AppState) => appealsColumnsSelector(state);

const appealsInlineFiltersSelector = (() => {
  const selector = createCachedSelector([selectConfigurationState, AppealsSelector.selectCurrentAppealType], (state, appealType) => {
    return getValueFromAppealsTableStructure('inlineFilters', state.tables?.appeals.tableStructures, appealType?.value);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectAppealsInlineFilters = (state: AppState) => appealsInlineFiltersSelector(state);

const appealsSideFiltersSelector = (() => {
  const selector = createCachedSelector([selectConfigurationState, AppealsSelector.selectCurrentAppealType], (state, appealType) => {
    return getValueFromAppealsTableStructure('sideFilters', state.tables?.appeals.tableStructures, appealType?.value);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();
export const selectAppealSideFilters = (state: AppState) => appealsSideFiltersSelector(state);
export const selectIncidentsFilters = createSelector(selectConfigurationState, state => {
  const filters = state.tables?.incidents?.filters;

  // Если filters — объект с inlineFilters и sideFilters, возвращаем его
  if (filters && typeof filters === 'object' && 'inlineFilters' in filters && 'sideFilters' in filters) {
    return filters;
  }

  // Значение по умолчанию, если filters отсутствует
  return { inlineFilters: [], sideFilters: [] };
});

export const selectAppealsAcceptingDelay = createSelector(selectConfigurationState, state => state.appealAcceptingDelayInSec);

export const selectCallsDuration = createSelector(selectConfigurationState, state => state.callsDurationInSec);

export const selectConsumersColumns = createSelector(selectConfigurationState, state => state.tables?.consumers.columns || []);

export const selectConsumersFilters = createSelector(selectConfigurationState, state => {
  const filters = state.tables?.consumers?.filters || [];

  // Если filters — объект с inlineFilters и sideFilters, возвращаем его
  if (filters && typeof filters === 'object' && 'inlineFilters' in filters && 'sideFilters' in filters) {
    return filters;
  }

  // Значение по умолчанию, если filters отсутствует
  return { inlineFilters: [], sideFilters: [] };
});

export const selectSuppliersColumns = createSelector(selectConfigurationState, state => state.tables?.managementCompanies.columns || []);

export const selectSuppliersFilters = createSelector(selectConfigurationState, (state): TableCommonStructure['filters'] => {
  const filters = state.tables?.managementCompanies?.filters;

  // Если filters — объект с inlineFilters и sideFilters, возвращаем его
  if (filters && typeof filters === 'object' && 'inlineFilters' in filters && 'sideFilters' in filters) {
    return filters;
  }

  // Значение по умолчанию, если filters отсутствует
  return { inlineFilters: [], sideFilters: [] };
});
export const selectAppealsActions = createSelector(selectConfigurationState, state => state.tables?.appeals.appealActions || []);
export const selectRequisitionKinds = createSelector(
  selectConfigurationState,
  state => state.tables?.appeals.appealRequisitionKindOptions || []
);

export const selectCallsColumns = createSelector(selectConfigurationState, state => state.tables?.calls.columns || []);

export const selectCallsFilters = createSelector(selectConfigurationState, state => {
  const filters = state.tables?.calls.filters;

  // Если filters — объект с inlineFilters и sideFilters, возвращаем его
  if (filters && typeof filters === 'object' && 'inlineFilters' in filters && 'sideFilters' in filters) {
    return filters;
  }

  // Значение по умолчанию, если filters отсутствует
  return { inlineFilters: [], sideFilters: [] };
});

export const selectMastersColumns = createSelector(selectConfigurationState, state => state.tables?.masters.columns || []);

export const selectMastersFilters = createSelector(selectConfigurationState, state => {
  const filters = state.tables?.masters.filters;

  // Если filters — объект с inlineFilters и sideFilters, возвращаем его
  if (filters && typeof filters === 'object' && 'inlineFilters' in filters && 'sideFilters' in filters) {
    return filters;
  }

  // Значение по умолчанию, если filters отсутствует
  return { inlineFilters: [], sideFilters: [] };
});

export const selectStatsFilters = createSelector(selectConfigurationState, () => []);

export const selectMailingsColumns = createSelector(selectConfigurationState, state => state.tables?.mailing.columns || []);

export const selectDashboardValues = createSelector(selectConfigurationState, state => state.dashboardUrl);

export const selectLoading = createSelector(selectConfigurationState, state => state.tablesAreLoading);
export const selectError = createSelector(selectConfigurationState, state => state.tablesLoadingError);

export const selectPercentTax = createSelector(selectConfigurationState, state => state.percentTax);

export const selectMinTax = createSelector(selectConfigurationState, state => state.minTax);

export const selectIncidentsColumns = createSelector(selectConfigurationState, state => state.tables?.incidents?.columns ?? []);

const speechNotificationsColumnsSelector = (() => {
  const selector = createCachedSelector([selectConfigurationState], state => {
    const columns = state.tables?.speechNotifications.columns || [];

    const getCustomColumn = (column: TableColumn) => {
      const customColumnKey = isTableCustomColumn(column.key) ? column.key : null;

      return Object({
        Header: getLabel(column.label),
        accessor: column.key,
        id: column.key,
        Cell: customColumnKey ? getCustomCell(customColumnKey) : null,
      });
    };
    const getTableColumns = (columns: TableColumn[]): Column<SpeechNotification>[] => {
      return columns.map(column => {
        const customColumnKey = isTableCustomColumn(column.key) ? column.key : null;

        return customColumnKey
          ? getCustomColumn(column)
          : Object({
              Header: getLabel(column.label),
              id: column.key,
              accessor: column.key,
            } as Column);
      });
    };

    return getTableColumns(columns);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectSpeechNotificationsColumns = (state: AppState) => speechNotificationsColumnsSelector(state);
