import { isSameDay, startOfDay } from 'date-fns';
import { boolean, date, mixed, object, string } from 'yup';

export enum DEFAULT_ERROR_MESSAGE {
  REQUIRED = 'обязательное поле*',
  WRONG_FORMAT = 'неверный формат',
  WRONG_VALUE = 'неверное значение',
  MAX_LENGTH = 'не более 200 знаков',
  FILE_EXTENSION_IS_NOT_VALID = 'недопустимое расширение файла',
  FILE_SIZE_IS_NOT_VALID = 'недопустимый размер файла',
}

export const validationScheme = object().shape({
  validateExtraFields: boolean(),
  incidentType: mixed().oneOf(['accident', 'scheduled_work']).required(DEFAULT_ERROR_MESSAGE.REQUIRED),
  appealKindId: string().required(DEFAULT_ERROR_MESSAGE.REQUIRED),
  text: string().required(DEFAULT_ERROR_MESSAGE.REQUIRED),
  supplierId: string().required(DEFAULT_ERROR_MESSAGE.REQUIRED),
  responsibleId: string().required(DEFAULT_ERROR_MESSAGE.REQUIRED),
  masterId: string().when('validateExtraFields', {
    is: true,
    then: schema => schema.required(DEFAULT_ERROR_MESSAGE.REQUIRED),
    otherwise: schema => schema.nullable().notRequired(),
  }),
  dateEnd: date().when('validateExtraFields', {
    is: true,
    then: schema => schema.min(startOfDay(new Date()), 'Нельзя создать инцидент в прошлом').required(DEFAULT_ERROR_MESSAGE.REQUIRED),
    otherwise: schema => schema.nullable().notRequired(),
  }),
  timeEnd: string().when(['validateExtraFields', 'dateEnd'], {
    is: (validateExtraFields: boolean, date: Date | null) => validateExtraFields && !!date,
    then: schema =>
      // eslint-disable-next-line func-names
      schema.required(DEFAULT_ERROR_MESSAGE.REQUIRED).test('is-min-time', 'Нельзя создать уведомление в прошлом', function (value) {
        const { date } = this.parent;
        const minDate = new Date();
        const isMinDay = isSameDay(minDate, date);
        if (isMinDay) {
          const minHours = minDate.getHours();
          const minMinutes = minDate.getMinutes();
          const [hours, minutes] = value.split(':');
          return minHours === +hours ? minMinutes <= +minutes : minHours < +hours;
        }
        return true;
      }),
    otherwise: schema => schema.nullable().notRequired(),
  }),
});
