import { Hint, TextRegular } from 'app/typography/text';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatTime } from '../../../helpers/date/time';
import { Colors } from '../../../themes/colors';
import { FlexWithSpacing } from '../../../typography/flex';
import { Icon } from '../IconBackground';
import { Preloader } from '../Preloader';
import { AudioPlayerType } from './audio-player-type.enum';
import { MAX_PLAYBACK_RATE, MIN_PLAYBACK_RATE, PLAYBACK_RATE_DELTA } from './audio-player.constants';

export interface AudioPlayerProps {
  className?: string;
  type: AudioPlayerType;
  src?: string;
  error?: boolean;
  playerRef?: RefObject<HTMLDivElement>;
  onClose?: () => void;
  onPlayClick?: (beforeLoad?: () => void, afterLoad?: () => void) => Promise<void>;
}

export const AudioPlayer = ({ src, className, type, onClose, error, playerRef, onPlayClick }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const progressBar = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (audioPlayer.current && src) {
      audioPlayer.current.src = src;
    }
  }, [src, audioPlayer]);

  useEffect(() => {
    if (!src && isPlaying) {
      setIsPlaying(false);
    }
  }, [isPlaying]);

  const onLoadedData = e => {
    setDuration(e.currentTarget.duration);
  };

  const togglePlayPause = async () => {
    if (!isPlaying) {
      await onPlayClick?.(
        () => setIsLoading(true),
        () => setIsLoading(false)
      );
    }
    if (audioPlayer.current && !error) {
      if (isPlaying) {
        audioPlayer.current.pause();
      } else {
        audioPlayer.current.play();
      }
      setIsPlaying(prev => !prev);
    }
  };

  const changeRange = e => {
    const current = typeof e === 'number' ? currentTime + e : e.target.value || e.currentTarget.currentTime;

    const percent = ((current / duration) * 100).toFixed(2);

    if (audioPlayer.current && (typeof e === 'number' || e.target.value)) {
      audioPlayer.current.currentTime = current;
    }

    setCurrentTime(current);
    changePlayerCurrentTime(percent);
  };

  const changePlayerCurrentTime = percent => {
    if (progressBar.current) {
      progressBar.current.style.setProperty('--seek-before-width', `${percent}%`);
    }
  };

  const onEnded = () => {
    setIsPlaying(false);
  };

  const onChangeRateClick = e => {
    if (error) {
      return;
    }

    if (audioPlayer.current && playbackRate + e > MIN_PLAYBACK_RATE && playbackRate + e <= MAX_PLAYBACK_RATE) {
      audioPlayer.current.playbackRate += e;
      setPlaybackRate(audioPlayer.current.playbackRate);
    }
  };

  return (
    <AudioPlayerComponent ref={playerRef} type={type} className={className} spacing="0.625rem">
      <audio title="ddd" ref={audioPlayer} onTimeUpdate={changeRange} onLoadedData={onLoadedData} onEnded={onEnded} />
      {type === AudioPlayerType.Extended && (
        <StyledIcon
          icon="/assets/icons.svg#rewind-left"
          className={type}
          disabled={!!error}
          onClick={() => onChangeRateClick(-PLAYBACK_RATE_DELTA)}
        />
      )}
      {!isLoading && (
        <PlayIcon
          className={type}
          isPlaying={isPlaying}
          icon={`/assets/icons.svg#${isPlaying ? 'pause' : 'play'}`}
          disabled={!!error}
          onClick={togglePlayPause}
        />
      )}
      {isLoading && <StyledPreloader />}
      {type === AudioPlayerType.Extended && (
        <StyledIcon
          icon="/assets/icons.svg#rewind-right"
          className={type}
          disabled={!!error}
          onClick={() => onChangeRateClick(PLAYBACK_RATE_DELTA)}
        />
      )}
      <ProgressBar
        className={type}
        ref={progressBar}
        value={currentTime}
        max={duration}
        type="range"
        disabled={!!error}
        onChange={changeRange}
      />
      {type === AudioPlayerType.Extended && <PlaybackRate>{playbackRate !== 1 ? `x ${playbackRate}` : ''}</PlaybackRate>}
      <TextRegular>{!error ? `${formatTime(currentTime)}/${formatTime(duration)}` : 'Ошибка'}</TextRegular>
      {type === AudioPlayerType.Simplified && <StyledIcon icon="/assets/icons.svg#close" className={type} onClick={onClose} />}
    </AudioPlayerComponent>
  );
};

const StyledIcon = styled(Icon)<{ disabled?: boolean }>`
  cursor: pointer;

  &.extended {
    color: ${Colors.Blue800};

    :hover {
      color: ${Colors.Blue900};
    }
  }

  &.simplified {
    color: ${Colors.Grey700};

    :hover {
      color: ${Colors.Blue600};
    }

    :active {
      color: ${Colors.Blue700};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      color: ${Colors.Grey700} !important;
  `}
`;

const PlayIcon = styled(StyledIcon)<{ isPlaying: boolean; disabled: boolean }>`
  &.simplified {
    color: ${props => (props.isPlaying ? Colors.Blue700 : Colors.Grey700)};
  }

  ${({ disabled }) =>
    disabled &&
    `
      && {
        color: ${Colors.Grey700};
       
       :hover {
          color: ${Colors.Grey700};
        }
      }
  `}
`;

const StyledPreloader = styled(Preloader)`
  width: 25px;
  height: 25px;

  &:after {
    border-width: 4px;
    border-color: ${Colors.Blue700} transparent ${Colors.Blue700} transparent;
  }
`;

const ProgressBar = styled.input`
  --bar-bg: ${Colors.Grey700};
  --seek-before-width: 0;
  --seek-before-color: ${Colors.Blue700};
  --knobby: ${Colors.Blue800};

  &.simplified {
    width: 150px;
    height: 2px;

    :before {
      height: 2px;
    }

    ::-webkit-slider-runnable-track {
      height: 2px;
    }

    ::-webkit-slider-thumb {
      margin: -4px 0 0 0;
    }
  }

  &.extended {
    width: 226px;
    height: 4px;

    :before {
      height: 4px;
    }

    ::-webkit-slider-runnable-track {
      height: 4px;
    }

    ::-webkit-slider-thumb {
      margin: -3px 0 0 0;
    }
  }

  appearance: none;
  background: var(--bar-bg);
  border-radius: 50%;
  position: relative;
  outline: none;

  :before {
    content: '';
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  ::-webkit-slider-runnable-track {
    background: var(--bar-bg);
    border-radius: 0.625rem;
    position: relative;
    width: 100%;
    outline: none;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }
  
  :disabled {
    cursor: not-allowed;

    ::-webkit-slider-thumb {
      cursor: not-allowed;
      background: var(--bar-bg);
    }
    }
  }
`;

const AudioPlayerComponent = styled(FlexWithSpacing)<{ type: AudioPlayerType }>`
  padding: 9px 10px 10px;
  background: ${props => (props.type === AudioPlayerType.Simplified ? Colors.MainBackground : Colors.Blue400)};
  ${props => props.type === AudioPlayerType.Simplified && 'box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08)'};
  border-radius: 6px;
  align-items: center;
  color: ${Colors.Grey700};
`;

const PlaybackRate = styled(Hint)`
  color: ${Colors.Blue800};
  width: 2.125rem;
`;
