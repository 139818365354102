import { EntityState } from 'store/models/entity-state';
import { createSlice } from '@reduxjs/toolkit';
import { getChosenFilter, resetSomeFilters } from 'app/helpers/filters/get-chosen-filter';
import { ChosenFilters } from 'app/models/common/chosen-filters';
import { Supplier } from 'app/models/supplier';
import { Call } from 'app/models/call';
import { Appeal } from 'app/models/appeal';
import { initialEntityState } from '../constants/initial-entity-state';
import * as SuppliersActions from './suppliers.actions';
import { SUPPLIERS_STORE_NAME } from './suppliers.actions';
import * as CallsActions from '../calls/calls.actions';
import { replaceOnce } from '../../app/helpers/arrays';

export interface SuppliersState extends EntityState {
  suppliers: Supplier[];
  tableSuppliers: Supplier[];
  suppliersTotalCount: number;
  chosenFilters: ChosenFilters;

  selectedSupplier?: Supplier;
  selectedSuppliersAppeals: Appeal[];
  selectedSuppliersAppealsTotalCount: number;
  selectedSuppliersCalls: Call[];
  selectedSuppliersCallsTotalCount: number;

  appealsLoading: boolean;
  callsLoading: boolean;

  appealsLoadingError: any;
  callsLoadingError: any;
  error: any;
}

export const suppliersInitialState: SuppliersState = {
  suppliers: [],
  tableSuppliers: [],
  suppliersTotalCount: 0,
  chosenFilters: {},

  selectedSupplier: undefined,
  selectedSuppliersAppeals: [],
  selectedSuppliersAppealsTotalCount: 0,
  selectedSuppliersCalls: [],
  selectedSuppliersCallsTotalCount: 0,

  appealsLoading: false,
  callsLoading: false,

  appealsLoadingError: undefined,
  callsLoadingError: undefined,
  error: undefined,
  ...initialEntityState,
};

const suppliersSlice = createSlice({
  name: SUPPLIERS_STORE_NAME,
  initialState: suppliersInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(SuppliersActions.GetSuppliersForTable.init, state => {
        state.loading = true;
      })
      .addCase(SuppliersActions.GetSuppliersForTable.success, (state, { payload }) => {
        state.loading = false;
        if (!payload.suppliers) return;
        state.tableSuppliers = payload.page === 1 ? payload.suppliers : state.suppliers.concat(payload.suppliers);
        state.suppliersTotalCount = payload.suppliersTotalCount;
      })
      .addCase(SuppliersActions.GetSuppliersForTable.failure, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(SuppliersActions.GetSuppliers.init, state => {
        state.loading = true;
      })
      .addCase(SuppliersActions.GetSuppliers.success, (state, { payload }) => {
        state.suppliers = payload;
        state.loading = false;
      })
      .addCase(SuppliersActions.GetSuppliers.failure, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      })
      .addCase(SuppliersActions.GetSupplierById.init, state => {
        state.loading = true;
      })
      .addCase(SuppliersActions.GetSupplierById.success, (state, { payload }) => {
        state.loading = false;
        state.selectedSupplier = payload;
      })
      .addCase(SuppliersActions.GetSupplierById.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(SuppliersActions.LoadSupplierAppeals.init, state => {
        state.appealsLoading = true;
      })
      .addCase(SuppliersActions.LoadSupplierAppeals.success, (state, { payload }) => {
        state.appealsLoading = false;
        state.selectedSuppliersAppeals = payload.page === 1 ? payload.results : state.selectedSuppliersAppeals.concat(payload.results);
        state.selectedSuppliersAppealsTotalCount = payload.count;
      })
      .addCase(SuppliersActions.LoadSupplierAppeals.failure, (state, { payload }) => {
        state.appealsLoading = false;
        state.appealsLoadingError = payload;
      })
      .addCase(SuppliersActions.LoadSupplierCalls.init, state => {
        state.callsLoading = true;
      })
      .addCase(SuppliersActions.LoadSupplierCalls.success, (state, { payload }) => {
        state.callsLoading = false;
        state.selectedSuppliersCalls = payload.page === 1 ? payload.results : state.selectedSuppliersCalls.concat(payload.results);
        state.selectedSuppliersCallsTotalCount = payload.count;
      })
      .addCase(SuppliersActions.LoadSupplierCalls.failure, (state, { payload }) => {
        state.callsLoading = false;
        state.callsLoadingError = payload;
      })
      .addCase(SuppliersActions.setChosenFilter, (state, { payload }) => {
        state.chosenFilters = getChosenFilter(payload.filters.inlineFilters, payload.filters.sideFilters);
      })
      .addCase(SuppliersActions.changeChosenFilter, (state, { payload }) => {
        state.chosenFilters[payload.key] = payload.value;
      })
      .addCase(SuppliersActions.resetSomeFilters, (state, { payload }) => {
        state.chosenFilters = resetSomeFilters(state.chosenFilters, payload);
      })
      .addCase(CallsActions.UpdateCall.success, (state, { payload }) => {
        state.selectedSuppliersCalls = replaceOnce(state.selectedSuppliersCalls, ({ id }) => id === payload.id, payload);
      }),
});

export const suppliersReducer = suppliersSlice.reducer;
