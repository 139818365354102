import React from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import * as MailingsActions from 'store/mailings/mailings.actions';
import { ModalProps } from 'app/services/modal-service/modal.service';
import { Mailing } from 'app/models/mailing';
import { StyledDialog } from './styled';
import { DialogBody } from './components/DialogBody';
import { DialogHeader } from './components/DialogHeader';
import { useLockBodyScroll } from '../../../../helpers';
import { DialogContentProps } from './components/types';

export type MailingDialogProps = {
  mailing?: Mailing;
} & ModalProps &
  Pick<DialogContentProps, 'initFields' | 'disableTemplate'>;

export const MailingDialog = ({ mailing, initFields, onClose = () => {}, disableTemplate }: MailingDialogProps) => {
  useActionListener((action: AnyAction) => {
    if (action.type === MailingsActions.CreateMailing.success.type || action.type === MailingsActions.UpdateMailing.success.type) {
      onClose();
    }
  });

  useLockBodyScroll();

  return (
    <StyledDialog
      headerContent={<DialogHeader mailing={mailing} />}
      body={<DialogBody mailing={mailing} initFields={initFields} disableTemplate={disableTemplate} onClose={onClose} />}
      onClose={onClose}
      className="mailing-dialog"
    />
  );
};

export default React.memo(MailingDialog);
