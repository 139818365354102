import styled from 'styled-components';
import { Table } from 'app/components/shared';
import { css } from 'styled-components/macro';
import { Colors } from '../../../themes/colors';

export const CallHistoryTable = styled(Table)<{ themeWhite?: boolean }>`
  padding: 0 10px;

  ${({ themeWhite }) =>
    themeWhite &&
    css`
      background-color: ${Colors.White};
      border-radius: 10px;
      margin-top: 20px;
    `}

  thead {
    border-top: none;

    th {
      padding: 20px 10px 15px;
    }
  }
` as typeof Table;
