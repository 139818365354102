/* eslint-disable  no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars  */
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { ModalProps } from 'app/services/modal-service/modal.service';
import { HeaderSmall } from 'app/typography/text';
import { Appeal } from 'app/models/appeal';
import { FormField } from 'app/components/shared';
import { selectMasters, selectMastersOptions } from 'store/masters/masters.selectors';
import { Option } from 'app/models/common/option';
import { Master } from 'app/models/master';
import { AppealsActions, AppealsSelectors } from 'store/appeals';
import { MastersActions } from 'store/masters';
import { useActionListener } from 'app/helpers/actions/action-listener.hook';
import { useAction } from 'app/helpers/actions/use-action';
import { UpdateAppealRequest } from 'app/services/appeals-service/dtos/update-appeal-request';
import { AssignButton, ErrorText, SelectMasterDialogBody, SelectMasterDialogComponent, StyledDropdown, StyledPreloader } from './styled';

interface AppealSelectMasterDialogProps extends ModalProps {
  appeal: Appeal;
}

export const AppealSelectMasterDialog = ({ appeal, onClose = () => {} }: AppealSelectMasterDialogProps) => {
  const dispatch = useDispatch();
  const masters = useSelector(selectMasters, shallowEqual);
  const updating = useSelector(AppealsSelectors.selectUpdateInProgress, shallowEqual);
  const mastersOptions = useSelector(selectMastersOptions, shallowEqual);

  const [selectValue, setSelectValue] = useState<Option<string>>(
    { value: appeal?.handyman?.id, label: appeal?.handyman?.fullName } || mastersOptions[0]
  );

  const [error, setError] = useState<string>('');
  const companyId = useMemo(() => appeal.managementCompany?.id || appeal.mcAddress || appeal.utilityCompany?.id, [appeal]);
  const loadMasters = useAction(MastersActions.LoadMasters.init);

  const disableMasters = !companyId || !mastersOptions.length;

  const assignMaster = React.useCallback(() => {
    const master = masters.find(master => master.id === selectValue.value);

    const { id, consumer, address, dispatcher, managementCompany, utilityCompany } = appeal;

    if (master) {
      const updateAppeal: UpdateAppealRequest = {
        id,
        consumerId: consumer?.id,
        addressId: address?.id,
        address: address.address,
        dispatcherId: dispatcher?.id,
        utilityCompanyId: utilityCompany?.id,
        managementCompanyId: managementCompany?.id,
        handymanId: master?.id,
      };
      dispatch(AppealsActions.AssignMaster.init(updateAppeal));
    } else {
      setError('Мастер не выбран');
    }
  }, [appeal, dispatch, masters, selectValue.value]);

  useActionListener((action: AnyAction) => {
    if (action.type === AppealsActions.AssignMaster.success.type) onClose();
  });

  React.useEffect(() => {
    loadMasters({
      managementCompany__in: companyId ? [companyId] : undefined,
      position__id__in: [appeal.appealRequisitionKind],
      per_page: 1000,
    });
  }, [appeal.appealRequisitionKind, companyId, loadMasters]);

  return (
    <SelectMasterDialogComponent
      headerContent={<HeaderSmall>Назначить мастера</HeaderSmall>}
      body={
        <SelectMasterDialogBody spacing="20px">
          <FormField placeholder="Выберите мастера *">
            <StyledDropdown
              options={mastersOptions}
              value={selectValue}
              onChange={value => setSelectValue(value as Option<string>)}
              disabled={disableMasters}
            />
            {!!error && <ErrorText>{error}</ErrorText>}
          </FormField>
          {updating ? (
            <StyledPreloader />
          ) : (
            <AssignButton mod="primary" onClick={assignMaster} disabled={!selectValue || !selectValue?.value || !!error || disableMasters}>
              Назначить
            </AssignButton>
          )}
        </SelectMasterDialogBody>
      }
      onClose={onClose}
    />
  );
};
