import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ConfigurationService } from 'app/services/configuration-service/configuration.service';
import { APPEAL_CATEGORIES } from 'app/constants/appeals';
import { SuppliersActions } from 'store/suppliers';
import * as AppealsActions from '../appeals/appeals.actions';
import * as ConsumersActions from '../consumers/consumers.actions';
import * as ConfigurationActions from './configuration.actions';
import * as CallsActions from '../calls/calls.actions';
import * as MastersActions from '../masters/masters.actions';
import { sortTableColumnsOfEachEntity } from './helpers/sort-table-columns-of-each-entity';
import { Setting } from '../../app/models/configuration';
import { SettingsService } from '../../app/services/settings-sercive';
import { setIncidentsChosenFilters } from '../incidents/incidents.actions';

function* tablesConfigurationWatcher() {
  try {
    const response = yield call(ConfigurationService.getTablesConfiguration);
    const tablesConfiguration = sortTableColumnsOfEachEntity(response);

    yield put(ConfigurationActions.GetTablesConfiguration.success(tablesConfiguration));

    yield put(AppealsActions.setFilters(tablesConfiguration.appeals.tableStructures));

    yield put(AppealsActions.setChosenFilter(tablesConfiguration.appeals.appealTypes[0].id));
    yield put(ConsumersActions.setChosenFilter({ filters: tablesConfiguration.consumers.filters }));
    yield put(CallsActions.setChosenFilter({ filters: tablesConfiguration.calls.filters }));
    yield put(MastersActions.setChosenFilter({ filters: tablesConfiguration.masters.filters }));
    yield put(SuppliersActions.setChosenFilter({ filters: tablesConfiguration.managementCompanies.filters }));

    yield put(setIncidentsChosenFilters({ filters: tablesConfiguration.incidents.filters || [] }));

    yield put(
      AppealsActions.changeAppealType({
        type: APPEAL_CATEGORIES.requisition,
        types: tablesConfiguration.appeals.appealTypes.map(type => ({ label: type.label, value: type.id })),
      })
    );
  } catch (e: any) {
    yield put(ConfigurationActions.GetTablesConfiguration.failure(e));
  }
}

function* settingsWorker() {
  try {
    const settings: Setting[] = yield call(SettingsService.loadSettings);

    yield put(ConfigurationActions.GetSettings.success(settings));
  } catch (e: any) {
    yield put(ConfigurationActions.GetSettings.failure(e));
  }
}

export default function* watcher() {
  return yield all([
    takeLatest(ConfigurationActions.GetTablesConfiguration.init, tablesConfigurationWatcher),
    takeLatest(ConfigurationActions.GetSettings.init.type, settingsWorker),
  ]);
}
