import { AddressesData } from 'app/pages/Mailings/components/AddressesList';
import { MailingStatus } from './mailing-status';
import { Brand } from '../../../utils/types/types.common';
import { MailingTemplateId } from '../../services/mailings-service/dtos/get-mailing-templates-request';

export type MailingId = Brand<string, 'MailingId'>;
export interface Mailing {
  id: MailingId;
  text: string;
  date: Date;
  status: MailingStatus;
  number_recipients?: number;
  notification_type: string;
  addresses?: AddressesData[];
  templateText?: string;
  macros: Macros;
  templateId: MailingTemplateId;
}

export type Macros = Record<string, MacrosWithValue | string>;

export type MacrosWithValue = {
  value?: string;
  type: string;
  model: string;
  title: string;
  order?: number;
};

export enum MailingTemplateIdDict {
  CRUSH = '87f6ef89-83e0-478e-89d3-06c26ff3dc46',
  PLANNED = '71bafb4a-f408-487c-9a0f-357a49311a61',
}
