import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Incident, IncidentId, IncidentStatus } from '../../../../../../models/incident/incident';
import { EditIncident, GetIncidentById } from '../../../../../../../store/incidents/incidents.actions';
import { ModalService } from '../../../../../../services/modal-service/modal.service';
import { IncidentForm } from '../../../../../Incidents/components/IncidentForm/IncidentForm';
import { OptionMenu } from '../../../../../../models/common';
import { Icon, Menu } from '../../../../../../components/shared';
import { Colors } from '../../../../../../themes/colors';
import { LoadComments } from '../../../../../../../store/comments/comments.actions';
import { MailingId, MailingTemplateIdDict } from '../../../../../../models/mailing';
import { selectSelectedIncident } from '../../../../../../../store/incidents/incidents.selectors';
import { MailingDialog } from '../../../../../Mailings/components/MailingDialog';
import { GetMailingTemplates } from '../../../../../../../store/mailings/mailings.actions';
import { MailingTemplate, MailingTemplateId } from '../../../../../../services/mailings-service/dtos/get-mailing-templates-request';
import { ConfirmModal } from '../../../../../../components/Modals/ConfirmModal/ConfirmModal';
import * as MailingsActions from '../../../../../../../store/mailings/mailings.actions';
import { useActionListener } from '../../../../../../helpers/actions/action-listener.hook';
import { selectMailingTemplates } from '../../../../../../../store/mailings/mailings.selectors';

type IncidentsActionsProps = {
  incidentId: IncidentId | undefined;
  status: IncidentStatus | undefined | null;
};

const getMacros = (template: MailingTemplate | undefined, incident: Incident | null) => {
  if (!template || !incident) return;

  const macros = { ...template.macros };

  const macroEntries = [
    { key: 'supplier', value: incident.supplier?.id },
    { key: 'dateEnd', value: incident.planned_end_date },
    { key: 'dateStart', value: incident.planned_start_date },
  ];

  macroEntries.forEach(({ key, value }) => {
    if (value) {
      macros[key] = { ...macros[key], value };
    }
  });

  return macros;
};

export const IncidentsActions = React.memo<IncidentsActionsProps>(props => {
  const dispatch = useDispatch();
  const { status } = props;

  const incident = useSelector(selectSelectedIncident, shallowEqual);
  const mailingTemplates = useSelector(selectMailingTemplates, shallowEqual);

  const onOptionClickHandler = React.useCallback(
    (status: IncidentStatus) => {
      if (!props.incidentId) return;
      dispatch(EditIncident.init({ id: props.incidentId, status }));
      dispatch(LoadComments.init({ id: String(props.incidentId), entity: 'incident' }));
    },
    [dispatch, props.incidentId]
  );

  const onDeleteMailingHandler = React.useCallback(
    (id: MailingId) => {
      if (!id) return;
      dispatch(MailingsActions.DeleteMailing.init({ id }));
    },
    [dispatch]
  );

  const getToWorkHandler = React.useCallback(() => {
    if (!incident) return;
    ModalService.openModal(IncidentForm, { incidentId: props.incidentId, incident, getToWork: true });
  }, [props.incidentId]);

  const onConfirmModalClickHandler = React.useCallback(
    (id: MailingId) => {
      const templateId =
        incident?.incident_type === 'accident'
          ? (MailingTemplateIdDict.CRUSH as MailingTemplateId)
          : (MailingTemplateIdDict.PLANNED as MailingTemplateId);

      const template = mailingTemplates?.find(template => template.id === templateId);

      ModalService.openModal(ConfirmModal, {
        title: 'Для инцидента существует оповещение',
        text: 'Удалить его и создать новое?',
        textButton: 'Да',
        onClick: () => onDeleteMailingHandler(id),
        onClose: () =>
          ModalService.openModal(MailingDialog, {
            disableTemplate: true,
            initFields: {
              templateId,
              macros: getMacros(template, incident),
              addresses: incident?.addresses,
              supplierId: incident?.supplier?.id,
              incidentId: incident?.id,
            },
          }),
      });
    },
    [incident, mailingTemplates, onDeleteMailingHandler]
  );

  const options: OptionMenu[] = React.useMemo(() => {
    if (status === IncidentStatus.Canceled || status === IncidentStatus.Completed) return [];

    const templateId =
      incident?.incident_type === 'accident'
        ? (MailingTemplateIdDict.CRUSH as MailingTemplateId)
        : (MailingTemplateIdDict.PLANNED as MailingTemplateId);

    const template = mailingTemplates?.find(template => template.id === templateId);

    const isNew = status === IncidentStatus.New;

    return [
      {
        label: 'Редактировать',
        callback: () => incident && ModalService.openModal(IncidentForm, { incidentId: props.incidentId, incident, getToWork: false }),
      },
      {
        label: 'Отменить',
        callback: () => onOptionClickHandler(IncidentStatus.Canceled),
      },
      {
        label: isNew ? 'В работу' : 'Выполнено',
        callback: () => (isNew ? getToWorkHandler() : onOptionClickHandler(IncidentStatus.Completed)),
      },
      {
        label: 'Создать оповещение',
        callback: () =>
          incident?.pushnotification?.id
            ? onConfirmModalClickHandler(incident?.pushnotification?.id)
            : ModalService.openModal(MailingDialog, {
                disableTemplate: true,
                initFields: {
                  templateId,
                  macros: getMacros(template, incident),
                  addresses: incident?.addresses,
                  supplierId: incident?.supplier?.id,
                  incidentId: incident?.id,
                },
              }),
      },
    ];
  }, [getToWorkHandler, incident, mailingTemplates, onConfirmModalClickHandler, onOptionClickHandler, props.incidentId, status]);

  React.useEffect(() => {
    dispatch(GetMailingTemplates.init());
  }, [dispatch]);

  useActionListener(action => {
    if (!incident?.id) return;

    if (
      action.type === MailingsActions.DeleteMailing.success.type ||
      action.type === MailingsActions.UpdateMailing.success.type ||
      action.type === MailingsActions.CreateMailing.success.type
    ) {
      dispatch(GetIncidentById.init(incident?.id));
    }
  });

  if (!options.length) return null;
  return (
    <Menu options={options}>
      <MenuIcon icon="/assets/icons.svg#menu" />
    </Menu>
  );
});

const MenuIcon = styled(Icon)`
  fill: ${Colors.Grey700} !important;
  cursor: pointer;
`;
