import { createSlice } from '@reduxjs/toolkit';
import { EntityState } from '../models/entity-state';
import { initialEntityState } from '../constants/initial-entity-state';
import { Incident } from '../../app/models/incident/incident';
import {
  changeIncidentsChosenFilter,
  CreateIncident,
  EditIncident,
  GetIncidentById,
  GetIncidents,
  GetRelatedAppeals,
  INCIDENTS_STORE_NAME,
  resetIncidentsSomeFilters,
  setIncidentsChosenFilters,
} from './incidents.actions';
import { IncidentsQueryParams } from '../../app/services/incidents-service/dtos/incidents-query-params';
import { replaceOnce } from '../../app/helpers/arrays';
import { Appeal } from '../../app/models/appeal';
import { ChosenFilters } from '../../app/models/common';
import { getChosenFilter, resetSomeFilters } from '../../app/helpers/filters/get-chosen-filter';
import { getNewFilterValue } from '../../app/helpers/filters/get-filter-new-value';
import { TableFilter } from '../../app/models/configuration';

export interface IncidentsState extends EntityState {
  incidents: Incident[];
  selectedIncident: Incident | null;
  incidentsTotalCount: number;
  relatedAppeals: Array<Partial<Appeal>>;
  query?: IncidentsQueryParams;
  filters: TableFilter[];
  chosenFilters: ChosenFilters;
  error?: any;
}

export const incidentsInitialState: IncidentsState = {
  incidents: [],
  incidentsTotalCount: 0,
  selectedIncident: null,
  relatedAppeals: [],
  chosenFilters: {},
  filters: [],
  query: undefined,
  error: undefined,
  ...initialEntityState,
};

const incidentsSlice = createSlice({
  name: INCIDENTS_STORE_NAME,
  initialState: incidentsInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(GetIncidents.init, (state, { payload }) => {
        state.loading = true;
        state.query = payload || incidentsInitialState.query;
      })
      .addCase(GetIncidents.success, (state, { payload }) => {
        state.loading = false;
        if (!payload.results) return;
        state.incidents = payload?.page === 1 ? payload.results : state.incidents.concat(payload.results);
        state.incidentsTotalCount = payload.count;
      })
      .addCase(GetIncidents.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(CreateIncident.init, state => {
        state.creating = true;
      })
      .addCase(CreateIncident.success, (state, { payload }) => {
        state.creating = false;
        state.selectedIncident = payload;
        state.incidents = [payload, ...state.incidents];
      })
      .addCase(CreateIncident.failure, (state, { payload }) => {
        state.creating = true;
        state.selectedIncident = null;
        state.error = payload;
      })

      .addCase(GetIncidentById.init, state => {
        state.loading = true;
      })
      .addCase(GetIncidentById.success, (state, { payload }) => {
        state.loading = false;
        state.selectedIncident = payload;
      })
      .addCase(GetIncidentById.failure, (state, { payload }) => {
        state.loading = false;
        state.selectedIncident = null;
        state.error = payload;
      })
      .addCase(EditIncident.init, state => {
        state.updating = true;
      })
      .addCase(EditIncident.success, (state, { payload }) => {
        state.updating = false;
        state.selectedIncident = payload;
        state.incidents = replaceOnce(state.incidents, ({ id }) => id === payload.id, payload);
      })
      .addCase(EditIncident.failure, (state, { payload }) => {
        state.updating = false;
        state.error = payload;
      })
      .addCase(GetRelatedAppeals.init, state => {
        state.loading = true;
      })
      .addCase(GetRelatedAppeals.success, (state, { payload }) => {
        state.loading = false;
        state.relatedAppeals = payload;
      })
      .addCase(GetRelatedAppeals.failure, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(setIncidentsChosenFilters, (state, { payload }) => {
        state.chosenFilters = getChosenFilter(payload.filters.inlineFilters, payload.filters.sideFilters);
      })

      .addCase(changeIncidentsChosenFilter, (state, { payload }) => {
        state.chosenFilters[payload.key] = getNewFilterValue(payload.value);
      })

      .addCase(resetIncidentsSomeFilters, (state, { payload }) => {
        state.chosenFilters = resetSomeFilters(state.chosenFilters, payload);
      })

      .addDefaultCase(() => {}),
});

export const incidentsReducer = incidentsSlice.reducer;
