import React from 'react';
import { Cell } from 'react-table';
import { Call } from 'app/models/call';
import { ModalService } from 'app/services/modal-service/modal.service';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SuppliersActions, SuppliersSelectors } from 'store/suppliers';
import { CALLS_HISTORY_COLUMNS, ConsumerCallsHistoryCell } from '../../../../components/Tabs/constants/calls-history.columns';
import { CallDialog } from '../../../Calls/components/CallDialog';
import { EmptyTable, FileIcon, Text } from '../../../../components/Tabs/constants/elements';
import { CallHistoryTable } from '../../../../components/Tabs/CallHistoryTab/styled';
import { SupplierId } from '../../../../models/supplier';

interface CallHistoryTabProps {
  supplierId: string | undefined;
}

export const CallHistoryTab = React.memo<CallHistoryTabProps>(props => {
  const dispatch = useDispatch();
  const calls = useSelector(SuppliersSelectors.selectSelectedSupplierCalls, shallowEqual);
  const isLoading = useSelector(SuppliersSelectors.selectSupplierCallsLoading, shallowEqual);
  const totalCount = useSelector(SuppliersSelectors.selectSelectedSupplierCallsTotalCount, shallowEqual);

  const [page, setPage] = React.useState(1);

  const cellHandleClick = (cell: Cell<Call>) => {
    const call = cell.row.original;

    if (cell.column.id !== ConsumerCallsHistoryCell.Record) {
      ModalService.openModal(CallDialog, { call });
    }
  };

  const handleNextPageLoad = React.useCallback(() => {
    setPage(prevState => prevState + 1);
  }, []);

  React.useEffect(() => {
    if (!props.supplierId) return;
    dispatch(
      SuppliersActions.LoadSupplierCalls.init({
        id: props.supplierId as SupplierId,
        page,
      })
    );
  }, [props.supplierId, page, dispatch]);

  if (!calls.length) {
    return (
      <EmptyTable>
        <FileIcon icon="/assets/icons.svg#phone down" />
        <Text>В истории нет звонков</Text>
      </EmptyTable>
    );
  }

  return (
    <CallHistoryTable
      isInfinity
      nextPage={handleNextPageLoad}
      dataLength={totalCount}
      columns={CALLS_HISTORY_COLUMNS}
      data={calls}
      onCellClick={cellHandleClick}
      isLoading={isLoading && page === 1}
    />
  );
});
