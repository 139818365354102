import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { SideMenu } from 'app/components/shared';
import { useFilters } from 'app/helpers/hooks/use-filters';
import { ChosenFilters } from 'app/models/common/chosen-filters';
import { TableFilterDirection, TableFilterPlace } from 'app/models/common/table-filter-properties';
import { TableFilter } from 'app/models/configuration/table-structure';
import { ModalProps } from 'app/services/modal-service/modal.service';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from 'types/AppState.type';
import { Selector } from 're-reselect';
import { SideDynamicsFilters, ControlsContainer, StyledButton } from './styled';

interface SideMenuFilterProps extends ModalProps {
  filtersConfig: TableFilter[];
  selectorChosenFilters: Selector<AppState, ChosenFilters>;
  changeFilterAction: ActionCreatorWithPayload<any>;
  applyChangeFilterAction: ActionCreatorWithPayload<any>;
  resetFilterAction: ActionCreatorWithPayload<any>;
}

export const SideMenuFilter = ({
  filtersConfig,
  selectorChosenFilters,
  changeFilterAction,
  applyChangeFilterAction,
  resetFilterAction,
  onClose = () => {},
}: SideMenuFilterProps) => {
  const chosenFilters = useSelector(selectorChosenFilters, shallowEqual);

  const { onFilterChange, resetSomeFilters } = useFilters({
    chosenFilters,
    onFilterChangeAction: applyChangeFilterAction,
    someFiltersResetAction: resetFilterAction,
  });

  const applyButtonClick = () => {
    onFilterChange();
    onClose();
  };

  const handleResetClick = () => {
    resetSomeFilters(filtersConfig.map(filter => filter.key));
    onClose();
  };

  return (
    <SideMenu
      title="Фильтры"
      body={
        <SideDynamicsFilters
          filters={filtersConfig}
          filterPlace={TableFilterPlace.Side}
          direction={TableFilterDirection.Column}
          spacing="0.625rem"
          changeFilterAction={changeFilterAction}
          chosenFilters={chosenFilters}
        />
      }
      footer={
        <ControlsContainer spacing="1rem">
          <StyledButton mod="primary" onClick={applyButtonClick}>
            Применить
          </StyledButton>
          <StyledButton mod="secondary" onClick={handleResetClick}>
            Сбросить
          </StyledButton>
        </ControlsContainer>
      }
      onClose={onClose}
    />
  );
};
