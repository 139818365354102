import { AppealAttachment } from 'app/models/appeal/appeal-attachment';
import {
  Appeal,
  AppealAdditionalFields,
  AppealChangesHistory,
  AppealConsumer,
  AppealDictionary,
  AppealMessage,
  AppealWork,
  AppealsQueryParams,
  AppealId,
  GetAppealCallsApiParams,
} from 'app/models/appeal';
import { Call } from 'app/models/call';
import { CancelToken } from 'axios';
import { APPEAL_CATEGORIES } from 'app/constants/appeals';
import { HttpService } from '../http.service';
import { CreateAppealRequest } from './dtos/create-appeal-request';
import { AddFeedbackDto } from './dtos/add-feedback-dto';
import { UpdateAppealRequest } from './dtos/update-appeal-request';
import { TableDto } from '../models/table.dto';
import { ChangeAppealSupplierDto } from './dtos/change-appeal-supplier-request';
import { GetAppealWorkPricesRequest } from './dtos/get-appeal-work-prices-request';
import { AppealChangesHistoryDto } from './dtos/appeal-changes-dto';
import { appealChangeHistoryDtoMapper } from './mappers/appeal-change-history.mapper';

class AppealsServiceImpl {
  private static apiEndpoint = '/api/appeals';
  private static apiDictionaries = '/api/v1/appeal';
  private static apiMessages = '/api/v1/message';

  getAppeals(params: AppealsQueryParams, cancelToken: CancelToken): Promise<TableDto<Appeal>> {
    const { per_page, page, ...queryFilters } = params;

    const { type, ...rest } = queryFilters;

    const filters = queryFilters.type && queryFilters.type !== APPEAL_CATEGORIES.none ? { type, ...rest } : { ...rest };

    const queryParams = {
      per_page: per_page || 100,
      page,
      ...filters,
    };

    return HttpService.get<TableDto<Appeal>>(`${AppealsServiceImpl.apiEndpoint}/`, {
      params: queryParams,
      cancelToken,
    }).then(response => response.data);
  }

  getAppeal(id: number): Promise<Appeal> {
    return HttpService.get<Appeal>(`${AppealsServiceImpl.apiEndpoint}/${id}/`).then(response => response.data);
  }

  getAppealConsumer(id: string): Promise<AppealConsumer> {
    return HttpService.get<AppealConsumer>(`${AppealsServiceImpl.apiEndpoint}/${id}/consumer/`).then(response => response.data);
  }

  getAppealChangesHistory(id: AppealId): Promise<AppealChangesHistory[]> {
    return HttpService.get<AppealChangesHistoryDto[]>(`${AppealsServiceImpl.apiEndpoint}/${id}/changes/`).then(response =>
      response.data.map(appealChangeHistoryDtoMapper)
    );
  }

  getAppealCalls(params: GetAppealCallsApiParams): Promise<TableDto<Call>> {
    return HttpService.get<TableDto<Call>>(`${AppealsServiceImpl.apiEndpoint}/${params.id}/calls/`, {
      params,
    }).then(response => response.data);
  }

  createAppeal(createAppealDto: Omit<CreateAppealRequest, 'address'>, cancelToken?: CancelToken): Promise<Appeal> {
    return HttpService.post<Appeal>(AppealsServiceImpl.apiEndpoint, createAppealDto, {
      cancelToken,
    }).then(response => response.data);
  }

  updateAppeal(updateAppealDto: Omit<UpdateAppealRequest, 'address'>): Promise<Appeal> {
    const { id, ...data } = updateAppealDto;
    return HttpService.put<Appeal>(`${AppealsServiceImpl.apiEndpoint}/${id}/`, data).then(response => response.data);
  }

  deleteAppeal(id: number) {
    return HttpService.delete<void>(`${AppealsServiceImpl.apiEndpoint}/${id}/`);
  }

  changeAppealStatus(id: string, status: string) {
    return HttpService.post<void>(`${AppealsServiceImpl.apiEndpoint}/${id}/status/`, {
      status,
    }).then(response => response.data);
  }

  closeAppeal(closeAppealDto: AddFeedbackDto): Promise<Appeal> {
    const { id, ...data } = closeAppealDto;
    return HttpService.post<Appeal>(`${AppealsServiceImpl.apiEndpoint}/${id}/feedback/`, data).then(response => response.data);
  }

  assignMaster(appealId: number): Promise<Appeal> {
    return HttpService.post<Appeal>(`${AppealsServiceImpl.apiEndpoint}/${appealId}/assign-master/`).then(response => response.data);
  }

  uploadAttachment(appealId: number, file: File): Promise<AppealAttachment> {
    const body = new FormData();
    body.append('file', file);

    return HttpService.post<AppealAttachment>(`${AppealsServiceImpl.apiEndpoint}/${appealId}/files`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(response => response.data);
  }

  changeAppealSupplier(dto: ChangeAppealSupplierDto): Promise<number> {
    const { appealId, ...data } = dto;
    return HttpService.put<number>(`${AppealsServiceImpl.apiEndpoint}/${appealId}/mc`, data).then(response => response.data);
  }

  async deleteAttachment(id: string): Promise<void> {
    await HttpService.delete<void>(`${AppealsServiceImpl.apiEndpoint}/files/${id}`);
  }

  getAppealTypes(): Promise<AppealDictionary[]> {
    return HttpService.get<AppealDictionary[]>(`${AppealsServiceImpl.apiDictionaries}/type/`).then(response => response.data);
  }

  getAppealCategories(): Promise<AppealDictionary[]> {
    return HttpService.get<AppealDictionary[]>(`${AppealsServiceImpl.apiDictionaries}/category/`).then(response => response.data);
  }

  getAppealKinds(): Promise<AppealDictionary[]> {
    return HttpService.get<AppealDictionary[]>(`${AppealsServiceImpl.apiDictionaries}/kind/`).then(response => response.data);
  }

  getAppealStatuses(): Promise<AppealDictionary[]> {
    return HttpService.get<AppealDictionary[]>(`${AppealsServiceImpl.apiDictionaries}/status/`).then(response => response.data);
  }

  getAppealAdditionalFields(): Promise<AppealAdditionalFields> {
    return HttpService.get<AppealAdditionalFields>(`${AppealsServiceImpl.apiDictionaries}/additional_fields/`).then(
      response => response.data
    );
  }

  getAppealWorks(params?: GetAppealWorkPricesRequest): Promise<AppealWork[]> {
    return HttpService.get<AppealWork[]>(`${AppealsServiceImpl.apiDictionaries}/work_price/`, { params }).then(response => response.data);
  }

  getAppealMessages(id: number): Promise<AppealMessage[]> {
    return HttpService.get<AppealMessage[]>(`${AppealsServiceImpl.apiMessages}/${id}/`).then(response => response.data);
  }

  setReadAppealMessages(id: number): Promise<void> {
    return HttpService.patch<void>(`${AppealsServiceImpl.apiMessages}/${id}/`).then(response => response.data);
  }

  createAppealMessage(dto: { id: number; text: string }): Promise<AppealMessage> {
    const { id, text } = dto;
    return HttpService.post<AppealMessage>(`${AppealsServiceImpl.apiMessages}/${id}/`, { text }).then(response => response.data);
  }
}

export const AppealsService = new AppealsServiceImpl();
