import { RecursiveValues } from '../../utils/types/types.common';

export enum RouteConstants {
  NewEntity = 'new',
  EditEntity = 'edit',
}

export type RouterParams = {
  consumerId?: string;
  supplierId?: string;
  appealId?: string;
  incidentId?: string;
  speechNotificationId?: string;
};

type ParamsDict = { [key in keyof RouterParams]-?: `:${key}` };

const RouterParamsDict: ParamsDict = {
  consumerId: ':consumerId',
  supplierId: ':supplierId',
  appealId: ':appealId',
  incidentId: ':incidentId',
  speechNotificationId: ':speechNotificationId',
};

const login = '/login';
const profile = '/profile';
const consumers = '/consumers';
const consumer = `${consumers}/${RouterParamsDict.consumerId}` as const;
const consumerCreate = `${consumers}/new`;
const consumerUpdate = `${consumer}/edit`;
const appeals = '/appeals';
const appeal = `${appeals}/${RouterParamsDict.appealId}` as const;
const appealEdit = `${appeal}/edit`;
const appealNew = `${appeals}/new`;
const calls = '/calls';
const suppliers = '/suppliers';
const supplier = `${suppliers}/${RouterParamsDict.supplierId}` as const;
const mailings = '/mailings';
const masters = '/masters';
const stats = '/stats';
const incidents = '/incidents';
const incident = `${incidents}/${RouterParamsDict.incidentId}` as const;
const speechNotifications = '/speech-notifications';
const speechNotification = `${speechNotifications}/${RouterParamsDict.speechNotificationId}` as const;

export const Pages = {
  PROFILE: profile,
  CONSUMERS: consumers,
  CONSUMER: consumer,
  CONSUMER_CREATE: consumerCreate,
  CONSUMER_UPDATE: consumerUpdate,
  APPEALS: appeals,
  APPEAL: appeal,
  APPEAL_EDIT: appealEdit,
  APPEAL_NEW: appealNew,
  CALLS: calls,
  SUPPLIERS: suppliers,
  SUPPLIER: supplier,
  MAILINGS: mailings,
  MASTERS: masters,
  STATS: stats,
  INCIDENTS: incidents,
  INCIDENT: incident,
  SPEECH_NOTIFICATIONS: speechNotifications,
  SPEECH_NOTIFICATION: speechNotification,
};

export type PagesRoutesType = RecursiveValues<typeof PagesRoutes> | `${RecursiveValues<typeof PagesRoutes>}/`;

const CommonRoutes = {
  LOGIN: login,
} as const;

export const PagesRoutes = {
  PAGES: Pages,
  COMMON: CommonRoutes,
} as const;

export enum RoutePath {
  Login = '/login',
  Profile = '/profile',
  Consumers = '/consumers',
  Consumer = '/consumers/:id',
  ConsumerCreate = '/consumers/new',
  ConsumerUpdate = '/consumers/:id/edit',
  Appeals = '/appeals',
  Calls = '/calls',
  Suppliers = '/suppliers',
  Supplier = '/suppliers/:id',
  Mailings = '/mailings',
  Masters = '/masters',
  Stats = '/stats',
  Incidents = '/incidents',
  Incident = '/incident',
  SpeechNotifications = '/speech-notifications',
  SpeechNotification = '/speech-notification/',
}

export const AppealRoute = {
  Appeals: '',
  Appeal: ':id',
  Edit: ':id/edit',
  New: RouteConstants.NewEntity,
};

export const RouteName = {
  [RoutePath.Login]: 'Авторизация',
  [RoutePath.Profile]: 'Профиль',
  [RoutePath.Consumers]: 'Потребители',
  [RoutePath.Consumer]: 'Потребитель',
  [RoutePath.ConsumerCreate]: 'Создание Потребителя',
  [RoutePath.ConsumerUpdate]: 'Редактирование Потребителя',
  [RoutePath.Appeals]: 'Обращения',
  [AppealRoute.New]: 'Новое обращение',
  [AppealRoute.Edit]: 'Редактировать обращение',
  [AppealRoute.Appeal]: 'Обращение',
  [RoutePath.Calls]: 'Звонки',
  [RoutePath.Suppliers]: 'Контрагенты',
  [RoutePath.Supplier]: 'Контрагент',
  [RoutePath.Mailings]: 'Оповещения',
  [RoutePath.Masters]: 'Мастера',
  [RoutePath.Stats]: 'Аналитика',
  [RoutePath.Incidents]: 'Инциденты',
  [RoutePath.Incident]: 'Инцидент',
  [RoutePath.SpeechNotifications]: 'Реестр АГУ',
  [RoutePath.SpeechNotification]: 'АГУ',
};

export const toolbarRoutes: RoutePath[] = [
  RoutePath.Consumers,
  RoutePath.Appeals,
  RoutePath.Incidents,
  RoutePath.Calls,
  RoutePath.Suppliers,
  RoutePath.Mailings,
  RoutePath.Masters,
  RoutePath.Stats,
];
