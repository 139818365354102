import { createSlice } from '@reduxjs/toolkit';
import * as ConfigurationActions from './configuration.actions';
import { SelectSupplierDashboard, SettingType } from '../../app/models/configuration';
import { DEFAULT_MAX_APPEAL_ACCEPTING_OVERDUE_IN_SEC, DEFAULT_MAX_CALL_DURATION_IN_SEC } from '../../app/constants/max-call-duration-in-ms';
import { TableConfiguration } from './models/get-appeals-configuration-success.payload';

export const CONFIGURATION_STORE_NAME = 'configuration-state';

export interface ConfigurationState {
  tables?: TableConfiguration;
  tablesAreLoading: boolean;
  tablesLoadingError: any;
  appealAcceptingDelayInSec: number;
  callsDurationInSec: number;
  settingAreLoading: boolean;
  settingsLoadingError: any;
  dashboardUrl: string | SelectSupplierDashboard[];
  percentTax: number;
  minTax: number;
}

export const configurationInitialState: ConfigurationState = {
  tables: undefined,
  tablesAreLoading: false,
  tablesLoadingError: undefined,
  settingAreLoading: false,
  settingsLoadingError: undefined,
  appealAcceptingDelayInSec: DEFAULT_MAX_APPEAL_ACCEPTING_OVERDUE_IN_SEC,
  callsDurationInSec: DEFAULT_MAX_CALL_DURATION_IN_SEC,
  dashboardUrl: '',
  percentTax: 0,
  minTax: 0,
};

const configurationSlice = createSlice({
  name: CONFIGURATION_STORE_NAME,
  initialState: configurationInitialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(ConfigurationActions.GetTablesConfiguration.init, state => {
        state.tablesAreLoading = true;
      })
      .addCase(ConfigurationActions.GetTablesConfiguration.success, (state, { payload }) => {
        state.tables = payload;
        state.tablesAreLoading = false;
      })
      .addCase(ConfigurationActions.GetTablesConfiguration.failure, (state, { payload }) => {
        state.tablesAreLoading = false;
        state.tablesLoadingError = payload;
      })
      .addCase(ConfigurationActions.GetSettings.init, state => {
        state.settingAreLoading = true;
        state.settingsLoadingError = undefined;
      })
      .addCase(ConfigurationActions.GetSettings.success, (state, { payload }) => {
        state.settingAreLoading = false;

        const settingsDict = payload.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});

        state.appealAcceptingDelayInSec =
          Number(settingsDict[SettingType.AppealAcceptingDelayInSec]) || DEFAULT_MAX_APPEAL_ACCEPTING_OVERDUE_IN_SEC;

        state.callsDurationInSec = Number(settingsDict[SettingType.CallAcceptingDelayInSec]) || DEFAULT_MAX_CALL_DURATION_IN_SEC;
        state.dashboardUrl = settingsDict[SettingType.DashboardUrl];
        state.percentTax = Number(settingsDict[SettingType.PercentTax]) || 0;
        state.minTax = Number(settingsDict[SettingType.MinTax]) || 0;
      })
      .addCase(ConfigurationActions.GetSettings.failure, (state, { payload }) => {
        state.settingAreLoading = false;
        state.settingsLoadingError = payload;
      }),
});

export const configurationReducer = configurationSlice.reducer;
