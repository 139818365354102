import styled from 'styled-components';

export const ButtonContainer = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
