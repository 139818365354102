export enum PersonalInfoField {
  Contactor = 'contactor',
  ContactorPhone = 'contactorPhone',
  Address = 'address',
  UtilityCompanyByPhone = 'utilityCompany',
  UtilityCompanyByAddress = 'mcAddress',
  SectorByAddress = 'mcSector',
}

export const personalInfoFieldNameMap = {
  [PersonalInfoField.Contactor]: 'Контактное лицо',
  [PersonalInfoField.ContactorPhone]: 'Телефон',
  [PersonalInfoField.Address]: 'Адрес',
  [PersonalInfoField.UtilityCompanyByPhone]: 'Поставщик по телефону',
  [PersonalInfoField.UtilityCompanyByAddress]: 'Поставщик по адресу',
  [PersonalInfoField.SectorByAddress]: 'Участок по адресу',
};

export const personalInfoFields: PersonalInfoField[] = [
  PersonalInfoField.Contactor,
  PersonalInfoField.ContactorPhone,
  PersonalInfoField.Address,
  PersonalInfoField.UtilityCompanyByPhone,
  PersonalInfoField.UtilityCompanyByAddress,
  PersonalInfoField.SectorByAddress,
];
