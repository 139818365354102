import { createSelector } from '@reduxjs/toolkit';
import { convertDictionariesToOptions } from 'app/models/appeal';
import createCachedSelector from 're-reselect';
import { AppState } from '../../types/AppState.type';
import { AppealsState } from './appeals.reducer';
import { defaultCachedSelectorKeySelector } from '../../utils/selectors';

export const selectAppealsState: (state: AppState) => AppealsState = state => state.appealsState;

export const selectSelectedAppeal = createSelector(selectAppealsState, state => state.selectedAppeal);

export const selectSelectedAppealSubject = createSelector(selectSelectedAppeal, state => state?.subject);
export const selectSelectedAppealAttachedFiles = createSelector(selectSelectedAppeal, state => state?.attachedFiles);
export const selectSelectedAppealIsPayment = createSelector(selectSelectedAppeal, state => state?.isPayment);
export const selectCurrentAppealType = createSelector(selectAppealsState, state => state.selectedAppealType);
export const selectAppeals = createSelector(selectAppealsState, state => state.appeals);

export const selectAppealsTotalCount = createSelector(selectAppealsState, state => state.appealsTotalCount);

export const selectChosenFilter = createSelector(selectAppealsState, state => state.chosenFilters);

export const selectLoading = createSelector(selectAppealsState, state => state.loading);
export const selectCreateInProgress = createSelector(selectAppealsState, state => state.creating);
export const selectUpdateInProgress = createSelector(selectAppealsState, state => state.updating);
export const selectDeleteOnProgress = createSelector(selectAppealsState, state => state.deleting);

export const selectError = createSelector(selectAppealsState, state => state.error);

export const selectAppealCalls = createSelector(selectAppealsState, state => state.calls);
export const selectAppealCallsLoading = createSelector(selectAppealsState, state => state.callsLoading);
export const selectAppealCallsTotalCount = createSelector(selectAppealsState, state => state.callsTotalCount);

export const selectIsFiltered = createSelector(selectAppealsState, state => {
  // eslint-disable-next-line
  const { message__icontains, ...filters } = state.chosenFilters;
  return Object.values(filters).filter(Boolean).length > 0;
});

export const selectAppealTypes = createSelector(selectAppealsState, state => state.appealTypes);

/** Возвращает  опции для селекта типа заявки (жилищная, коммунальная, коммерческая и тд) */
const appealTypeOptionsSelector = (() => {
  const selector = createCachedSelector([selectAppealTypes], types => {
    if (!types) return [];
    return convertDictionariesToOptions(types);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectAppealTypeOptions = (state: AppState) => appealTypeOptionsSelector(state);

/** Возвращает лейбл типа заявки (жилищная, коммунальная, коммерческая и тд) */
const appealTypeLabelByIdSelector = (() => {
  const selector = createCachedSelector(
    [selectAppealTypes, (_S: AppState, appealTypeId: string | undefined) => appealTypeId],
    (types, appealTypeId) => {
      if (!appealTypeId) return '';

      return types?.find(type => type.id === appealTypeId)?.label;
    }
  )(defaultCachedSelectorKeySelector);

  return (state: AppState, appealTypeId: string | undefined) => selector(state, appealTypeId);
})();

export const selectAppealTypeLabelById = (appealTypeId: string | undefined) => (state: AppState) =>
  appealTypeLabelByIdSelector(state, appealTypeId);

export const selectAppealCategories = createSelector(selectAppealsState, state => state.appealCategories);

/** Возвращает категорию заявки (жалобы, заявки, предлжения тд) */
const appealCategoriesOptionsSelector = (() => {
  const selector = createCachedSelector([selectAppealCategories], categories => {
    if (!categories) return [];
    return convertDictionariesToOptions(categories).concat([{ label: 'Все', value: 'None' }]);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectAppealCategoriesOptions = (state: AppState) => appealCategoriesOptionsSelector(state);

export const selectAppealStatuses = createSelector(selectAppealsState, state => state.appealStatuses);

export const selectAppealsStatusesCounts = createSelector(selectAppealsState, state => state.statusesCounts);

const appealStatusesWithCountsSelector = (() => {
  const selector = createCachedSelector([selectAppealStatuses, selectAppealsStatusesCounts], (statuses, counts) => {
    if (!statuses?.length) return;

    return statuses?.map(item => {
      if (counts && counts[item.id]) {
        return { ...item, ...counts[item.id] };
      }
      return item;
    });
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectAppealStatusesWithCounts = (state: AppState) => appealStatusesWithCountsSelector(state);

/** Возвращает статус заявки (новая, в работе, отложена и тд) */
const appealStatusLabelByIdSelector = (() => {
  const selector = createCachedSelector(
    [selectAppealStatuses, (_S: AppState, statusId: string | undefined) => statusId],
    (statuses, statusId) => {
      if (!statusId) return '';

      return statuses?.find(status => status.id === statusId)?.label;
    }
  )(defaultCachedSelectorKeySelector);

  return (state: AppState, statusId: string | undefined) => selector(state, statusId);
})();

export const selectAppealStatusLabelById = (statusId: string | undefined) => (state: AppState) =>
  appealStatusLabelByIdSelector(state, statusId);

export const selectAppealKinds = createSelector(selectAppealsState, state => state.appealKinds);

/** Возвращает  опции для селекта вида заявки (электроснабжение, лифт, сантехника  и тд) */
const appealKindsOptionsSelector = (() => {
  const selector = createCachedSelector([selectAppealKinds], kinds => {
    if (!kinds) return [];
    return convertDictionariesToOptions(kinds);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectAppealKindOptions = (state: AppState) => appealKindsOptionsSelector(state);

export const selectAppealsQuery = createSelector(selectAppealsState, state => state.query);

export const selectAppealAdditionalFields = createSelector(selectAppealsState, state => state.appealAdditionalFields);

export const selectAppealWorks = createSelector(selectAppealsState, state => state.appealWorks);

const appealWorkOptionsSelector = (() => {
  const selector = createCachedSelector([selectAppealsState], state => {
    return state.appealWorks?.map(work => ({ value: work.id, label: work.label }));
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectAppealWorkOptions = (state: AppState) => appealWorkOptionsSelector(state);

export const selectAppealMessagesLength = createSelector(selectAppealsState, state => state.selectedAppealMessages.length);

const unreadAppealMessagesLengthSelector = (() => {
  const selector = createCachedSelector([selectAppealsState], state => {
    if (!state.selectedAppealMessages?.length) return 0;

    return state.selectedAppealMessages?.filter(message => !message.read && message.income).length;
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectUnreadAppealMessagesLength = (state: AppState) => unreadAppealMessagesLengthSelector(state);

const sortedAppealMessagesSelector = (() => {
  const selector = createCachedSelector([selectAppealsState], state => {
    if (!state.selectedAppealMessages?.length) return [];

    return [...state.selectedAppealMessages].sort((a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf());
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectSortedAppealMessages = (state: AppState) => sortedAppealMessagesSelector(state);

export const selectSelectedAppealSupplierId = createSelector(selectSelectedAppeal, state => {
  return state?.mcAddress || state?.managementCompanyId;
});

const newestAppealCreatedDateSelector = (() => {
  const selector = createCachedSelector([selectAppeals], appeals => {
    if (!appeals.length) return null;

    const filtered = appeals?.filter(appeal => !appeal?.newMsgCount);

    return filtered[0]?.createdAt;
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectLastAppealDate = (state: AppState) => newestAppealCreatedDateSelector(state);

export const selectActiveStatus = createSelector(selectAppealsState, state => state.activeStatusTab);

const selectAppealChangesHistory = createSelector(selectAppealsState, state => state.changesHistory);

const appealChangesHistorySelector = (() => {
  const selector = createCachedSelector([selectAppealChangesHistory], histories => {
    if (!histories?.length) return [];

    return histories?.filter((history, index) => index === histories.length - 1 || history?.changes?.length > 0);
  })(defaultCachedSelectorKeySelector);

  return (state: AppState) => selector(state);
})();

export const selectFilteredAppealChangesHistory = (state: AppState) => appealChangesHistorySelector(state);
