import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Call } from 'app/models/call';
import { Cell } from 'react-table';
import { AppealsActions } from 'store/appeals';
import * as AppealsSelectors from '../../../../../store/appeals/appeals.selectors';
import { CALLS_HISTORY_COLUMNS, ConsumerCallsHistoryCell } from '../../../../components/Tabs/constants/calls-history.columns';
import { CallDialog } from '../../../Calls/components/CallDialog';
import { EmptyTable, FileIcon, Text } from '../../../../components/Tabs/constants/elements';
import { CallHistoryTable } from '../../../../components/Tabs/CallHistoryTab/styled';
import { AppealId } from '../../../../models/appeal';
import { ModalService } from '../../../../services/modal-service/modal.service';

type CallHistoryTabProps = {
  appealId: AppealId;
};
export const CallHistoryTab = React.memo<CallHistoryTabProps>(props => {
  const dispatch = useDispatch();
  const calls = useSelector(AppealsSelectors.selectAppealCalls, shallowEqual);
  const isLoading = useSelector(AppealsSelectors.selectAppealCallsLoading, shallowEqual);
  const totalCount = useSelector(AppealsSelectors.selectAppealCallsTotalCount, shallowEqual);

  const [page, setPage] = React.useState(1);

  const cellHandleClick = (cell: Cell<Call>) => {
    const call = cell.row.original;

    if (cell.column.id !== ConsumerCallsHistoryCell.Record) {
      ModalService.openModal(CallDialog, { call });
    }
  };

  const handleNextPageLoad = React.useCallback(() => {
    setPage(prevState => prevState + 1);
  }, []);

  React.useEffect(() => {
    if (!props.appealId) return;

    dispatch(
      AppealsActions.GetAppealCalls.init({
        id: props.appealId,
        page,
      })
    );
  }, [dispatch, page, props.appealId]);

  if (!calls.length) {
    return (
      <EmptyTable>
        <FileIcon icon="/assets/icons.svg#phone down" />
        <Text>В истории нет звонков</Text>
      </EmptyTable>
    );
  }

  return (
    <CallHistoryTable
      isInfinity
      isLoading={isLoading && page === 1}
      nextPage={handleNextPageLoad}
      themeWhite
      columns={CALLS_HISTORY_COLUMNS}
      data={calls}
      onCellClick={cellHandleClick}
      dataLength={totalCount}
    />
  );
});
