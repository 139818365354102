import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import history from 'app/history';
import { RoutePath } from 'app/constants/route-path';
import { Row } from 'react-table';
import { isOverdue } from 'app/helpers/date/is-overdue';
import { ConfigurationSelectors } from 'store/configuration';
import { Appeal } from 'app/models/appeal';
import { APPEAL_STATUS } from 'app/constants/appeals';
import { ConsumersActions, ConsumersSelectors } from 'store/consumers';
import React from 'react';
import { EmptyTable, FileIcon, Text } from '../../../../components/Tabs/constants/elements';
import { APPEAL_HISTORY_COLUMNS, ConsumerAppealHistoryCell } from '../../../../components/Tabs/constants/appeal-history.columns';
import { AppealsTable, CLOSED_APPEAL_ROW_CLASS_NAME, OVERDUE_APPEAL_ROW_CLASS_NAME } from './styled';

interface AppealsHistoryTabProps {
  addressId: string | undefined;
  addressLabel: string | undefined;
}

export const AppealsHistoryTab = React.memo<AppealsHistoryTabProps>(({ addressId, addressLabel }) => {
  const dispatch = useDispatch();

  const appealAssignDelay = useSelector(ConfigurationSelectors.selectAppealsAcceptingDelay);
  const appeals = useSelector(ConsumersSelectors.selectConsumerAppealsByAddressId(addressId), shallowEqual);
  const isAppealsLoading = useSelector(ConsumersSelectors.selectAreConsumerAppealsLoading, shallowEqual);
  const totalCount = useSelector(ConsumersSelectors.selectConsumerAppealsTotalCount, shallowEqual);

  const [page, setPage] = React.useState(1);

  const cellHandleClick = cell => {
    const appeal = cell.row.original as Appeal;
    if (cell.column.id !== ConsumerAppealHistoryCell.Actions) {
      history.push(`${RoutePath.Appeals}/${appeal.id}`);
    }
  };

  const handleNextPageLoad = React.useCallback(() => {
    setPage(prevState => prevState + 1);
  }, []);

  const getRowClassName = (row: Row<Appeal>) => {
    const appeal = row.original;
    if (appeal.status === APPEAL_STATUS.closed || appeal.status === APPEAL_STATUS.canceled) return CLOSED_APPEAL_ROW_CLASS_NAME;
    if (appeal.status === APPEAL_STATUS.new && isOverdue(appeal.createdAt, appealAssignDelay)) {
      return OVERDUE_APPEAL_ROW_CLASS_NAME;
    }
    return '';
  };

  React.useEffect(() => {
    if (!addressLabel) return;
    dispatch(
      ConsumersActions.LoadConsumerAppeals.init({
        address: addressLabel,
        page,
      })
    );
  }, [addressLabel, dispatch, page]);

  if (!appeals.length) {
    return (
      <EmptyTable>
        <FileIcon icon="/assets/icons.svg#file" />
        <Text>В истории нет обращений</Text>
      </EmptyTable>
    );
  }

  return (
    <AppealsTable
      columns={APPEAL_HISTORY_COLUMNS}
      data={appeals}
      onCellClick={cellHandleClick}
      rowClassName={getRowClassName}
      isLoading={isAppealsLoading && page === 1}
      isInfinity
      nextPage={handleNextPageLoad}
      dataLength={totalCount}
    />
  );
});
