import { SpeechNotificationDto } from './dtos';
import { SpeechNotification } from '../../models/speech-notification';
import { TableDto } from '../models/table.dto';

export const getSpeechNotificationsMapperDto = (dto: TableDto<SpeechNotificationDto>): TableDto<SpeechNotification> => ({
  page: dto.page,
  per_page: dto.per_page,
  count: dto.count,
  results: dto.results.map(result => ({
    ...result,
    supplier_name: undefined,
    history: undefined,
    supplierName: result.supplier_name,
  })),
});

export const getSpeechNotificationMapperDto = (dto: SpeechNotificationDto): SpeechNotification => ({
  id: dto.id,
  text: dto.text,
  theme: dto.theme,
  supplierName: dto.supplier_name,
  active: dto.active,
  modifiedAt: dto.modifiedAt,
  history: dto.history?.map(history => ({
    reason: history.history_change_reason,
    createdAt: history.date,
    changedBy: {
      id: history.changed_by_dispatcher?.id || '',
      name: history?.changed_by_dispatcher?.name || '',
    },
    isChangedByConsumer: false,
    changes: history.changes?.map((history, index) => ({
      id: index,
      field: history.field,
      newValue: history.new_value,
      oldValue: history.old_value,
    })),
  })),
});

export const updateSpeechNotificationsMapperDto = (dto: SpeechNotificationDto): SpeechNotification => ({
  ...dto,
  history: dto.history?.map(history => ({
    reason: history.history_change_reason,
    createdAt: history.date,
    changedBy: {
      id: history.changed_by_dispatcher?.id || '',
      name: history?.changed_by_dispatcher?.name || '',
    },
    isChangedByConsumer: false,
    changes: history.changes?.map((history, index) => ({
      id: index,
      field: history.field,
      newValue: history.new_value,
      oldValue: history.old_value,
    })),
  })),
  supplierName: dto.supplier_name,
});
