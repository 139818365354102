import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { format, parse } from 'date-fns';
import { useFormik } from 'formik';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { MailingsSelectors } from 'store/mailings';
import { SuppliersSelectors } from 'store/suppliers';
import { selectServicesOptions } from '../../../../../../store/services/services.selectors';
import { Macros, MacrosWithValue, Mailing, MailingStatus, MailingStatusToLabelMap } from '../../../../../models/mailing';
import { AddressesData, AddressesList, addressFiasIdField } from '../../AddressesList';
import { validationScheme } from '../constants';
import * as MailingsActions from '../../../../../../store/mailings/mailings.actions';
import { ModalService } from '../../../../../services/modal-service/modal.service';
import { ColumnFlexWithPadding, FlexWithSpacing } from '../../../../../typography/flex';
import {
  AddressesError,
  ButtonsContainer,
  FormRow,
  RecipientsCount,
  StyledFormField,
  MailingSelect,
  MacrosContainer,
  MacrosFormField,
  TemplateText,
  MailingStatusField,
} from '../styled';
import { Button, Datepicker, FormField } from '../../../../../components/shared';
import { MailingDialog } from '../index';
import { GreyText } from '../../../../../typography/text';
import { MailingTemplateId } from '../../../../../services/mailings-service/dtos/get-mailing-templates-request';
import { MailingsService } from '../../../../../services/mailings-service/mailings.service';
import { getValidFormattedAddress } from '../../../../../components/shared/AddressSuggestions/helpers';
import AddressSuggestions from '../../../../../components/shared/AddressSuggestions/AddressSuggestions';
import { SupplierId } from '../../../../../models/supplier';
import { IncidentId } from '../../../../../models/incident/incident';

type MailingForm = {
  date: string;
  time: string;
  templateId: MailingTemplateId | string;
};

export type DialogContentProps = {
  mailing?: Mailing;
  onClose?: (result?: any) => void;
  initFields?: Partial<Mailing> & { supplierId?: SupplierId | null; incidentId?: IncidentId };
  disableTemplate?: boolean;
};

export const DialogBody = React.memo(({ mailing, initFields, onClose, disableTemplate }: DialogContentProps) => {
  const dispatch = useDispatch();
  const mailingTemplatesOptions = useSelector(MailingsSelectors.selectMailingTemplatesOptions, shallowEqual);
  const mailingTemplates = useSelector(MailingsSelectors.selectMailingTemplates, shallowEqual);
  const suppliersOptions = useSelector(SuppliersSelectors.selectSuppliersOptions);
  const servicesOptions = useSelector(selectServicesOptions, shallowEqual);

  const [macros, setMacros] = React.useState<Macros>(mailing?.macros || initFields?.macros || {});
  const [templateText, setTemplateText] = React.useState<string>('');
  const [isFormFilled, setIsFormFilled] = React.useState(false);

  const [addresses, setAddresses] = React.useState<AddressesData[]>(mailing?.addresses || initFields?.addresses || []);
  const [addressesValue, setAddressesValue] = React.useState<string>('');
  const [isBlur, setIsBlur] = React.useState(false);
  const [numberRecipients, setNumberRecipients] = React.useState<number | undefined>(mailing?.number_recipients ?? 0);

  const initialValues: MailingForm = React.useMemo(() => {
    const date = mailing?.date || initFields?.date;

    return {
      text: mailing?.text || initFields?.text || '',
      date: date ? format(new Date(date), 'yyyy-MM-dd') : '',
      time: date ? format(new Date(date), 'HH:mm') : '',
      templateId: mailing?.templateId || initFields?.templateId || mailingTemplatesOptions?.[0]?.value || '',
    };
  }, [
    initFields?.date,
    initFields?.templateId,
    initFields?.text,
    mailing?.date,
    mailing?.templateId,
    mailing?.text,
    mailingTemplatesOptions,
  ]);

  const servicesOptionsMap = React.useMemo(() => {
    const optionsMap = {};
    servicesOptions?.forEach(option => {
      optionsMap[option.value] = option;
    });
    return optionsMap;
  }, [servicesOptions]);

  const suppliersOptionsMap = React.useMemo(() => {
    const optionsMap = {};
    suppliersOptions?.forEach(option => {
      optionsMap[option.value] = option;
    });
    return optionsMap;
  }, [suppliersOptions]);

  const submitHandler = React.useCallback(
    (values, send?: boolean) => {
      if (!addresses.length) {
        setIsBlur(true);
        return;
      }

      const data = {
        date: parse(`${values.date} ${values.time}`, 'yyyy-MM-dd HH:mm', new Date()),
        addresses,
        macros: {},
      };

      Object.entries(macros).forEach(([key, macro]) => {
        if (typeof macro !== 'string') {
          if (macro?.type === 'DateTime') {
            const utcDate = macro?.value ? new Date(macro.value)?.toISOString() : null;
            data.macros[key] = { ...macro, value: utcDate };
          } else {
            data.macros[key] = macro;
          }
        }
      });

      if (mailing && mailing.id) {
        const updateMailing = {
          id: mailing.id,
          data,
          query: { send },
        };
        dispatch(MailingsActions.UpdateMailing.init(updateMailing));
      } else {
        const createMailing = {
          data: { ...data, templateId: values.templateId, incidentId: initFields?.incidentId },
          query: { send },
        };
        dispatch(MailingsActions.CreateMailing.init(createMailing));
      }
    },
    [addresses, dispatch, initFields?.incidentId, macros, mailing]
  );

  const { values, setFieldValue, errors, handleChange, isValid } = useFormik<MailingForm>({
    initialValues,
    validationSchema: validationScheme,
    onSubmit: values => submitHandler(values, false),
    validateOnChange: true,
  });

  const isOldMailing =
    mailing?.status === MailingStatus.Error || mailing?.status === MailingStatus.Planned || mailing?.status === MailingStatus.Sended;
  const buttonIsDisabled = !isValid || !addresses.length || isOldMailing || !isFormFilled;

  const removeAddress = React.useCallback((index: number) => {
    setAddresses(prev => prev.filter((_, i) => i !== index));
  }, []);

  const onCopyClick = React.useCallback(() => {
    onClose?.();
    ModalService.openModal(MailingDialog, {
      initFields: {
        addresses: mailing?.addresses,
        templateId: mailing?.templateId,
        text: mailing?.text,
        date: mailing?.date,
        macros: mailing?.macros,
      },
    });
  }, [mailing?.addresses, mailing?.date, mailing?.macros, mailing?.templateId, mailing?.text, onClose]);

  const updateTemplateText = React.useCallback(
    (newMacros: Macros) => {
      let updatedText = mailingTemplates?.find(template => template.id === values.templateId)?.text || '';

      // Функция для замены макросов и добавления стилей
      const replaceMacro = (key: string, value: string, isFilled: boolean) => {
        const color = isFilled ? '#214E9D' : '#BDC2CE';
        const displayedValue = value ? `${value}` : `{${key}}`;
        return `<span style="color:${color};">${displayedValue}</span>`;
      };

      Object.entries(newMacros).forEach(([key, macro]) => {
        if (typeof macro === 'string') return;

        if (macro?.type === 'select') {
          const selectedOption =
            macro.model === 'Service'
              ? servicesOptionsMap[macro?.value || '']
              : suppliersOptionsMap[macro?.value || initFields?.supplierId || ''];

          const label = selectedOption ? selectedOption.label : '';
          const isFilled = !!label;
          updatedText = updatedText.replace(`{${key}}`, replaceMacro(key, label, isFilled));
        } else if (macro?.type === 'DateTime') {
          const formattedDate = macro?.value ? format(new Date(macro.value), 'HH:mm dd.MM.yyyy') : '';
          const isFilled = !!formattedDate;
          updatedText = updatedText.replace(`{${key}}`, replaceMacro(key, formattedDate, isFilled));
        }
      });

      setTemplateText(updatedText);
    },
    [initFields?.supplierId, mailingTemplates, servicesOptionsMap, suppliersOptionsMap, values.templateId]
  );

  const handleMacroChange = React.useCallback(
    (key: string, value: string | number) => {
      value = typeof value === 'string' ? value : String(value);

      // Обновляем макрос только если это объект, а не строка
      const currentMacro = macros[key];
      const newMacros = {
        ...macros,
        [key]: typeof currentMacro === 'object' && currentMacro !== null ? { ...(currentMacro as MacrosWithValue), value } : value,
      };

      setMacros(newMacros);

      updateTemplateText(newMacros);
    },
    [macros, updateTemplateText]
  );

  const handleSelectChange = React.useCallback((key: string, value: string | number) => handleMacroChange(key, value), [handleMacroChange]);

  const handleDateChange = React.useCallback((key: string, value: string | number) => handleMacroChange(key, value), [handleMacroChange]);

  const onTemplateSelectChangeHandler = React.useCallback(
    (value: string | undefined | number, field: string) => {
      if (!value) return;

      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const onAddressChangeHandler = React.useCallback((event: DaDataSuggestion<DaDataAddress> | undefined) => {
    if (!event) return;

    const formattedAddress = getValidFormattedAddress(event.data);

    // Если адрес неполный, не обновляем значение
    if (!formattedAddress) return;

    const level = event.data.fias_level;
    const currentFiasId = event.data[addressFiasIdField[level]];

    // Обновляем состояние inputValue
    setAddressesValue(formattedAddress);

    // Добавляем адрес в список только при уникальном значении FIAS ID
    setAddresses(prev =>
      prev.find(address => address[addressFiasIdField[level]] === currentFiasId)
        ? prev
        : [...prev, { address: formattedAddress, [addressFiasIdField[level]]: currentFiasId }]
    );
  }, []);

  React.useEffect(() => {
    if (addresses?.length && !isOldMailing) {
      const getNumb = async () => {
        const { number } = await MailingsService.getNumberRecipients({ addresses });
        setNumberRecipients(number);
      };

      getNumb().then();
    }
  }, [addresses, dispatch, isOldMailing]);

  React.useEffect(() => {
    if (mailing?.id) {
      setMacros(mailing?.macros || {});
      const updatedText = mailing?.text;
      setTemplateText(updatedText);
    } else {
      const selectedTemplate = mailingTemplates?.find(template => template.id === values.templateId);

      if (!selectedTemplate) return;
      setMacros(initFields?.macros || selectedTemplate.macros || {});
      const updatedText = initFields?.text || selectedTemplate.text || '';
      setTemplateText(updatedText);
    }
  }, [
    initFields?.macros,
    initFields?.text,
    macros?.placement,
    mailing?.id,
    mailing?.macros,
    mailing?.text,
    mailingTemplates,
    values.templateId,
  ]);

  React.useEffect(() => {
    const isDateFilled = !!values.date;
    const isTimeFilled = !!values.time;
    const isTemplateIdFilled = !!values.templateId;
    const isAddressesFilled = !!addresses.length;

    let areMacrosFilled = true;

    Object.entries(macros).forEach(([_, macro]) => {
      if (typeof macro === 'string') return;

      if (macro?.type === 'DateTime' && !macro?.value) {
        areMacrosFilled = false;
      } else if (macro?.type === 'select' && (macro?.value === undefined || macro?.value === '')) {
        areMacrosFilled = false;
      } else if (macro?.value === undefined || macro?.value === '') {
        areMacrosFilled = false;
      }
    });

    setIsFormFilled(isDateFilled && isTimeFilled && isTemplateIdFilled && isAddressesFilled && areMacrosFilled);
  }, [values.date, values.time, values.templateId, addresses, macros]);

  React.useEffect(() => {
    updateTemplateText(macros);
  }, [updateTemplateText, macros, values.templateId]);

  return (
    <ColumnFlexWithPadding spacing="5px">
      <FlexWithSpacing spacing="20px">
        <MailingSelect
          id="templateId"
          name="templateId"
          label="Шаблон"
          icon="chevron-down"
          value={values.templateId}
          options={mailingTemplatesOptions || []}
          onChange={value => onTemplateSelectChangeHandler(value, 'templateId')}
          placeholder="Выберите шаблон оповещения"
          disabled={!!mailing?.id || disableTemplate}
        />

        <FormField placeholder="Дата рассылки">
          <Datepicker
            id="date"
            type="date"
            name="date"
            value={values.date}
            error={errors.date}
            onChange={handleChange}
            readOnly={isOldMailing}
            min={format(new Date(), 'yyyy-MM-dd')}
            disabled={isOldMailing}
          />
        </FormField>
        <FormField placeholder="Время рассылки">
          <Datepicker
            id="time"
            type="time"
            value={values.time}
            error={errors.time}
            onChange={handleChange}
            readOnly={isOldMailing}
            disabled={isOldMailing}
          />
        </FormField>
      </FlexWithSpacing>
      <StyledFormField placeholder="Адреса">
        {!isOldMailing && (
          <RecipientsCount>
            <span>Выбрано</span> {numberRecipients} получателей
          </RecipientsCount>
        )}

        <AddressSuggestions
          value={addressesValue}
          onChange={event => onAddressChangeHandler(event)}
          onClearInput={() => setIsBlur(true)}
          inputProps={{
            name: 'address',
            placeholder: 'Введите адрес',
            autoComplete: 'off',
            noError: true,
            disabled: isOldMailing,
          }}
        />

        {!!addresses.length && (
          <>
            <AddressesList addresses={addresses} onDeleteClick={removeAddress} readOnly={isOldMailing} />
          </>
        )}

        {!addresses.length && isBlur && <AddressesError>Выберите адрес</AddressesError>}
      </StyledFormField>
      <MacrosContainer>
        {macros &&
          Object.entries(macros)
            .sort(([, a], [, b]) => {
              // Приводим order к числу, если возможно, или используем значение по умолчанию
              const orderA = typeof a === 'object' && a !== null && 'order' in a ? Number(a.order) || 0 : 0;
              const orderB = typeof b === 'object' && b !== null && 'order' in b ? Number(b.order) || 0 : 0;
              return orderA - orderB;
            })
            .map(([key, macro]) => {
              if (typeof macro === 'string') return null;

              return (
                <MacrosFormField spacing="5px" key={key} placeholder={macro?.title ?? ''}>
                  {macro?.type === 'select' && macro?.model === 'Service' && (
                    <MailingSelect
                      id={key}
                      value={macro.value || ''}
                      placeholder="Выберите услугу"
                      options={servicesOptions || []}
                      onChange={e => handleSelectChange(key, e)}
                      icon="chevron-down"
                      disabled={isOldMailing}
                    />
                  )}

                  {macro?.type === 'select' && macro.model === 'Supplier' && (
                    <MailingSelect
                      id={key}
                      value={macro.value || ''}
                      placeholder="Выберите поставщика"
                      options={suppliersOptions || []}
                      onChange={e => handleSelectChange(key, e)}
                      icon="chevron-down"
                      disabled={isOldMailing}
                    />
                  )}

                  {macro?.type === 'DateTime' && (
                    <FlexWithSpacing spacing="10px">
                      <Datepicker
                        id={`${key}-date`}
                        type="date"
                        disabled={isOldMailing}
                        value={macro?.value ? format(new Date(macro?.value), 'yyyy-MM-dd') : ''}
                        onChange={e => {
                          const dateValue = e.target.value.trim();
                          if (dateValue) {
                            handleDateChange(
                              key,
                              `${e.target.value}T${macro.value ? macro.value.split('T')[1] : format(new Date(), 'HH:mm:ss')}`
                            );
                          } else {
                            handleDateChange(key, '');
                          }
                        }}
                        min={format(new Date(), 'yyyy-MM-dd')}
                      />
                      <Datepicker
                        id={`${key}-time`}
                        type="time"
                        value={macro.value ? format(new Date(macro.value), 'HH:mm') : ''}
                        disabled={isOldMailing}
                        onChange={e => {
                          const timeValue = e.target.value.trim();
                          if (timeValue) {
                            handleDateChange(
                              key,
                              `${macro.value ? macro.value.split('T')[0] : format(new Date(), 'yyyy-MM-dd')}T${timeValue}`
                            );
                          } else {
                            handleDateChange(key, '');
                          }
                        }}
                      />
                    </FlexWithSpacing>
                  )}
                </MacrosFormField>
              );
            })}
      </MacrosContainer>

      <FormRow spacing="20px" />
      <FormField placeholder="Заголовок и сообщение">
        <TemplateText>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: templateText }} />
        </TemplateText>

        {mailing?.status && (
          <MailingStatusField>
            {' '}
            <GreyText>Статус : </GreyText>
            {MailingStatusToLabelMap[mailing.status]}
          </MailingStatusField>
        )}
      </FormField>
      <ButtonsContainer>
        {!isOldMailing && (
          <Button mod="primary" onClick={() => submitHandler(values)} disabled={buttonIsDisabled}>
            Сохранить черновик
          </Button>
        )}
        {mailing?.id && (
          <Button mod="secondary" onClick={onCopyClick}>
            Создать дубликат
          </Button>
        )}

        {!isOldMailing && (
          <Button mod="primary" onClick={() => submitHandler(values, true)} disabled={buttonIsDisabled}>
            {mailing?.id ? 'Сохранить' : 'Создать оповещение'}
          </Button>
        )}
      </ButtonsContainer>
    </ColumnFlexWithPadding>
  );
});
